import React, { useContext, useEffect, useState } from 'react'
import { Select } from '../../../components/Form/Select'
import { Formik } from 'formik'
import { TextInput } from '../../../components/Form/TextInput'
import { ButtonV2, CoreButtonTypes } from '@metaforcelabs/metaforce-core'
import { githubLight } from '@uiw/codemirror-theme-github';
import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { useToastAction } from '../../../hooks/useToastAction';
import { DatasetContext } from '../../../contexts'
import { on } from 'mocha/lib/runner'
import { createDialogDefinitionDataset, deleteDialogDefinitionDataset, getDialogDefinitionDatasets, updateDialogDefinitionDataset, validateDialogDefinitionDataset } from '../../../api/dialogDefinitionDatasets'
import Modal from '../../../components/Modal'
import { useModalHelper } from '../../../hooks/useModalHelper'

export default function DialogDataset({ dialogDefinitionId }) {
    const deleteModalhelper = useModalHelper();
    const [selectedDataset, setSelectedDataset] = useState();
    const [rollbackDataset, setRollbackDataset] = useState();
    const [dialogDatasets, setDialogDatasets] = useState([]);
    const loadAction = useToastAction();
    const saveAction = useToastAction(true, "savedataset");
    const [validationErrors, setValidationErrors] = useState([]);
    useEffect(() => {
        load();
    }, [])

    useEffect(() => {
        console.log(selectedDataset);
    }, [selectedDataset])

    const hasChanges = () => {
        return rollbackDataset && JSON.stringify(rollbackDataset) !== JSON.stringify(selectedDataset);
    }

    const load = async () => {
        loadAction.execute(async () => {
            const datasets = await getDialogDefinitionDatasets(dialogDefinitionId);
            if (datasets.length > 0) {
                setSelectedDataset(datasets[0]);
                setRollbackDataset(datasets[0]);
            }
            setDialogDatasets(datasets);
        })
    }

    const handleCreateDataset = () => {
        saveAction.execute(async () => {
            const dataset = await createDialogDefinitionDataset(dialogDefinitionId, 'New Dataset');
            setDialogDatasets([...dialogDatasets, dataset]);
            setSelectedDataset(dataset);
            setRollbackDataset(dataset);
            setValidationErrors([]);
        }, 'Failed to create dataset');
    }

    const handleDeleteDataset = () => {
        deleteModalhelper.open();
    }

    const handleConfirmDeleteDataset = () => {
        saveAction.execute(async () => {
            const deleted = await deleteDialogDefinitionDataset(dialogDefinitionId, selectedDataset.id);
            deleteModalhelper.close();
            if (deleted) {
                const updatedDatasets = dialogDatasets.filter(x => x.id !== selectedDataset.id);
                setDialogDatasets(prev => updatedDatasets);
                setSelectedDataset(updatedDatasets.length > 0 ? updatedDatasets[0] : null);
                setRollbackDataset(updatedDatasets.length > 0 ? updatedDatasets[0] : null);
            }
            setValidationErrors([]);
        }, 'Failed to delete dataset');
    }

    const handleSaveDataset = () => {
        saveAction.execute(async () => {

            try {
                const updated = await updateDialogDefinitionDataset(dialogDefinitionId, selectedDataset.id, selectedDataset);
                if (updated) {
                    setDialogDatasets(prev => prev.map(x => x.id === selectedDataset.id ? selectedDataset : x));
                }
                setValidationErrors([]);
            } catch (error) {
                if (error.validationErrors && error.validationErrors.length > 0) {
                    setValidationErrors(error.validationErrors);
                }
                throw error;
            }

        },);
    }

    const handleValidateDataset = () => {
        saveAction.execute(async () => {
            try {
                await validateDialogDefinitionDataset(dialogDefinitionId, selectedDataset.id, selectedDataset);
                setValidationErrors([]);
            } catch (error) {
                if (error.validationErrors && error.validationErrors.length > 0) {
                    setValidationErrors(error.validationErrors);
                }
            }
        });
    }

    return (
        <>
            <div className="px-4 divide-y divide-gray-300">
                <DatasetContext.Provider
                    value={{
                        isLoading: loadAction.isExecuting || saveAction.isExecuting,
                        datasets: dialogDatasets || [],
                        validationErrors: validationErrors,
                        hasChanges: hasChanges,
                        onSave: handleSaveDataset,
                        onValidate: handleValidateDataset,
                        onCancel: () => { },
                        onImport: () => { },
                        onDelete: handleDeleteDataset,
                        onNew: handleCreateDataset,
                        onReset: () => setSelectedDataset(rollbackDataset)
                    }}>
                    <section className='py-4 max-w-4xl mx-auto'>
                        <ManageDataSetSection selectedDataset={selectedDataset} onDatasetChanged={dataset => {
                            setSelectedDataset(dataset)
                            setRollbackDataset(dataset);
                        }}
                        />
                    </section>
                    <section className='py-4'>
                        <DatasetEditor dataset={selectedDataset} onDatasetChange={(value) => {
                            setSelectedDataset(prev => ({ ...prev, datasetJson: value }))
                        }} />
                    </section>
                </DatasetContext.Provider>
            </div>
            <Modal
                isOpen={deleteModalhelper.isOpen}
                onClose={() => deleteModalhelper.close()}
                size={'medium'}
                title={
                    <>
                        Do you want to delete dataset <span className="text-lg leading-6 font-medium text-indigo-600">{selectedDataset?.name}</span>?
                    </>
                }
            >
                <div className="flex justify-end mt-5 sm:mt-6">
                    <button
                        type="button"
                        className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm"
                        onClick={(event) => handleConfirmDeleteDataset()}
                    >
                        Delete
                    </button>
                    <button
                        type="button"
                        className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => deleteModalhelper.close()}
                    >
                        Cancel
                    </button>
                </div>
            </Modal></>
    )
}

const DatasetEditor = ({ onDatasetChange, dataset }) => {
    const { validationErrors, isLoading, ...datasetContext } = useContext(DatasetContext);

    const handleDatasetChange = (value) => {
        onDatasetChange(value)
    }

    return dataset ? <div>
        <div>
            {validationErrors.length > 0 && (
                <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Validation Errors:</strong>
                    <ul className="list-disc list-inside text-sm">
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error.errors.join('. ')}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
        <CodeMirror readOnly={isLoading} height='750px' theme={githubLight} value={dataset?.datasetJson} extensions={[json({})]} onChange={
            (value) => {
                handleDatasetChange(value)
            }
        } />
    </div> :
        <div className='flex justify-center items-center h-64'>
            <div className='text-gray-400'>No Dataset Selected</div>
        </div>
}

const ManageDataSetSection = ({ selectedDataset, onDatasetChanged }) => {

    const { isLoading, datasets, ...datasetContext } = useContext(DatasetContext);

    const handleCreateDataset = () => {
        datasetContext.onNew();
    }

    const handleDeleteDataset = () => {
        datasetContext.onDelete();
    }

    const handleDatasetSelect = (datasetId) => {
        const dataset = datasets.find(x => x.id === datasetId)
        onDatasetChanged(dataset);
    }

    const handleDatasetNameChange = (value) => {
        onDatasetChanged(prev => ({ ...prev, name: value }))
    }

    return (
        <div className="">
            <div className="" >
                <div>
                    <div className="flex items-center space-x-4 mb-4 w-full">
                        <label htmlFor="dataset" className={'flex-none text-sm font-medium text-gray-700 w-24 flex justify-end'}>
                            <span>Data Set</span>
                        </label>
                        <div className='flex-1 '>
                            <Select id="dataset" defaultOptionText={isLoading ? '' : datasets.length == 0 ? 'No Datasets' : null} options={datasets.map(x => ({ value: x.id, name: x.name }))}
                                selectedValue={selectedDataset?.id} onChange={(value, name) => handleDatasetSelect(value)
                                }
                            />
                        </div>
                        <div>
                            <ButtonV2 label={'New'} type={CoreButtonTypes.cta} onClick={e => handleCreateDataset()} />
                        </div>
                        <div>
                            <ButtonV2 label={'Delete'} type={CoreButtonTypes.secondary} onClick={e => handleDeleteDataset()} />
                        </div>
                    </div>

                    <div className="flex items-center space-x-4">
                        <label htmlFor="dataset" className={'flex-none text-sm font-medium text-gray-700 w-24 flex justify-end'}>
                            Name
                        </label>
                        <div className='flex-1 ml-2 w-32'>
                            <TextInput name="name" value={selectedDataset?.name} onChange={(value, name) => handleDatasetNameChange(value)} />
                        </div>
                        <div className='w-32' ></div> {/* Spacer */}
                        <div className='w-32' ></div> {/* Spacer */}

                    </div>
                </div>
                <div className='flex space-x-2 mt-4 justify-end'>
                    <div className='justify-end mr-6 items-center flex space-x-2'>
                        <div><ButtonV2 disabled={isLoading } size='sm' type={CoreButtonTypes.white} label={"Validate"} onClick={() => datasetContext.onValidate()} /></div>
                        {/* <div><ButtonV2 size='sm' type={CoreButtonTypes.white} label={"Import"} /></div> */}
                    </div>
                    <div className='w-64 flex flex-none space-x-4 justify-end'>
                        <ButtonV2 size='md' type={CoreButtonTypes.cta} label={"Save"} onClick={() => datasetContext.onSave()} />
                        <ButtonV2 size='md' disabled={!datasetContext?.hasChanges()} onClick={() => datasetContext.onReset()} type={CoreButtonTypes.secondary} label={"Reset"} />
                    </div>
                </div>
            </div>

        </div>

    )
}
