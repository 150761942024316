import { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation, useParams } from "react-router-dom";
import * as Structures from "../../../structures";
import { ColumnContext, ElementContext, RowContext, ContainerContext, DesignActionbarContext } from '../../../contexts';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useNewIdGenerator } from './useNewIdGenerator';
import { designContexts, dialogDefinitionStatuses, editStates, elementTypes, dialogType, sidebarLeftTabs, sidebarRightTabs, dialogDesignerPageKeys, dialogDesignerPages } from '../../../utils/constants';
import _ from 'lodash';
import { useAutoSave } from '../../../hooks/useAutoSave';
import { useDialogDefinitionSchemaValidation } from '../../../hooks/useDialogDefinitionSchemaValidation';
import { useElementActions } from './useElementActions';
import { useColumnActions } from './useColumnActions';
import { useRowActions } from './useRowActions';
import { useContainerActions } from './useContainerActions';
import { useDefinitionManager } from "./useDefinitionManager";
import { useToastAction } from '../../../hooks/useToastAction';
import { publishDialogDefinition, activateDialogDefinition, deactivateDialogDefinition, updateDialogDefinitionProperties } from "../../../api/dialogDefinition";
import { useHotkeys } from 'react-hotkeys-hook';
import { useDebounceCallback } from '@react-hook/debounce';
import { useSelector, useDispatch } from 'react-redux';
import { undoDialogDefinition, setDialogDefinition, setActiveStatus, publish, setEditState } from '../../../slices/dialogDefinitionSlice';
import "./styles.scss";
import { ensureUniquePropertyNamesForAllContainers, ensureUniquePropertyNamesForAllElements, getAllContainers, getAllElements } from '../../../utils/dialogDefinitions';
import { DragDropContext } from "react-beautiful-dnd";
import { DndContext, PointerSensor, useSensors, useSensor, MeasuringStrategy, DragOverlay } from '@dnd-kit/core';
import { GlobalDndContextWrapper } from "./Dnd/GlobalDndContextWrapper"
import { useDndHook } from "./Dnd/dndHook"
import HelperSidebar from '../../../components/HelperSidebar';
import { ArrowsExpandIcon } from '@heroicons/react/outline'
import { getAllThemeStyle } from '../../../api/themeStyles';
import { Badge } from '@metaforcelabs/metaforce-core';
import ElementRightClickMenu from '../../../components/ElementMenuContext';
import { activateDialogObject, deactivateDialogObject } from '../../../api/dialogObjects';
import { activateDialogTemplate, deactivateDialogTemplate } from '../../../api/dialogTemplates';
import { useModalHelper } from '../../../hooks/useModalHelper';
import Confirm from '../../../components/Confirm';
import SidebarLeft, { MobileLeftSidebarToggle } from './sidebar-left';
import SidebarRight, { MobileRightSidebarToggle } from './sidebar-right';
import { MobileView } from '../../../components/Devices/mobileView';
import React from 'react';
import DialogPageViewSelect from './dialogPageViewSelect';
import DialogDataset from './DialogDataset';

export function DialogDesign({
  onLoad,
  onSave,
  disableAddObjects,
  disableAddElements,
  disableAddContainer,
  disableAddRow,
  disableAddColumn,
  disableSettings,
  disableLayoutSettings,
  disablePublish,
  disablePreview,
  disableExport,
  disableDuplicateContainer,
  isObjectTemplate = false,
  isDialogTemplate = false,
  schemaAditionalProperties,
  disableDatasets
}) {
  const elementTypeKeys = Object.keys(elementTypes);
  const location = useLocation();
  const { objectId, dialogKey } = useParams();
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current);
  const dialogDefinitionRef = useRef();
  const mainDialogRef = useRef();
  const containersLength = dialogDefinition?.containers?.length;
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const [openProperties, setOpenProperties] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [currentElement, setCurrentElement] = useState();
  const [currentColumn, setCurrentColumn] = useState();
  const [currentContainer, setCurrentContainer] = useState();
  const [currentRow, setCurrentRow] = useState();
  const [currentRowId, setCurrentRowId] = useState();
  const [currentElementId, setCurrentElementId] = useState();
  const [currentColumnId, setCurrentColumnId] = useState();
  const [currentContainerId, setCurrentContainerId] = useState();
  const [newContainerId, setNewContainerId] = useState();
  const [newRowId, setNewRowId] = useState();
  const [newColumnId, setNewColumnId] = useState();
  const [newElementId, setNewElementId] = useState();
  const [selectedLayoutType, setSelectedLayoutType] = useState(designContexts.element);
  const [propertiesToShow, setPropertiesToShow] = useState();
  const lastUpdatedAtRef = useRef(null);
  const [schemaErrors, setSchemaErrors] = useState([]);
  const deactivateDefinitionAction = useToastAction();
  const activateDefinitionAction = useToastAction(true);
  const smartformNameAction = useToastAction();
  const multipleValuesAction = useToastAction();

  const [isClickedThingLocked, setIsClickedThingLocked] = useState(false);
  const [showAllGrid, setShowAllGrid] = useState(false)
  const [openObjects, setOpenObjects] = useState(false)
  const [popoverOpenFromDesignWithContainerId, setPopoverOpenFromDesignWithCointainerId] = useState(false);
  const [canMoveHorizontal, setCanMoveHorizontal] = useState(true)
  const [canMoveVertical, setCanMoveVertical] = useState(true)
  const [selectedZoom, setSelectedZoom] = useState(1);
  const [isMobileView, setIsMobileView] = useState(false);
  const [activeTabLeft, setActiveTabLeft] = useState(sidebarLeftTabs.actions);
  const [activeTabRight, setActiveTabRight] = useState(sidebarRightTabs.items);
  const [showRowOnDoubleClick, setShowRowOnDoubleClick] = useState(true)
  const [showRightClickMenu, setShowRightClickMenu] = useState(false)
  const [designElementForContextMenu, setDesignElementForContextMenu] = useState(null)
  const [rightClickMenuPosition, setRightClickMenuPosition] = useState({ x: 0, y: 0 })
  const [allStyles, setAlLStyles] = useState([{ name: 'Default', id: '0', config: { page: { backgroundColor: "#fff", margin: "0px", padding: "0px" } } }]);
  const themeStyleSelected = useMemo(() => allStyles.find(style => style.id === dialogDefinition?.themeStyleId), [allStyles, dialogDefinition?.themeStyleId]);
  const [currentScrollPositionTop, setCurrentScrollPositionTop] = useState(0);
  const confirmRemoveModalHelper = useModalHelper();
  const [typeToRemove, setTypeToRemove] = useState();
  const [smartformName, setSmartformName] = useState(dialogDefinition?.name);
  const [mobileSideBarRightVisible, setMobileSideBarRightVisible] = useState(false);
  const [mobileSideBarLeftVisible, setMobileSideBarLeftVisible] = useState(false);
  const [activeDesignerPage, setActiveDesignerPage] = useState(dialogDesignerPageKeys.html);
  useEffect(() => {
    if (mainDialogRef.current !== null) {
      mainDialogRef.current?.scrollTo({ top: currentScrollPositionTop, behaviour: "smooth" })
    }
  }, [currentContainerId])

  //create new independent selectedLayoutType badge because the current selectedLayoutType is obsolete and cause bugs 
  const [badgeSelectedType, setBadgeSelectedType] = useState(designContexts.element);

  // const [mainDialogScrollY, setMainDialogScrollY] = useState(0)
  // const { scrollY } = useScrollObserverByRef(mainDialogRef);

  const idGenerator = useNewIdGenerator()
  const schemaValidator = useDialogDefinitionSchemaValidation(schemaAditionalProperties);

  const publishAction = useToastAction(true, 1);

  // Tracks inline actionbar's current elementId. Need a state value to force re-renders
  // when it gets updated, but also need a ref to be able to track the immediate value as
  // soon as it gets set/unset. If only state is used, and if a user clicks one element, then another
  // element, the result can be that the new elementId gets set, but then the unset even fires
  // and then sets the action bar element id to null.
  const [actionBarSelectedElementId, setActionBarSelectedElementId] = useState();
  const actionBarSelectedElementIdRef = useRef();

  // useEffect(() => {
  //   mainDialogRef.current && mainDialogRef.current.addEventListener("scroll", () => {
  //       setMainDialogScrollY(mainDialogRef.current.scrollTop)
  //   })
  // }, [mainDialogRef?.current?.scrollTop])

  useEffect(() => {
    load(true);
    return () => saveOnUnmount();
  }, []);


  const saveOnUnmount = async () => {
    // Prevent unnecessarily saving the dialog definition if there are no pending saves. 
    // Fixes a long standing bug that saving on unmount introduced that by leaving the designer, the
    // dialog definition will always be saved which will always force it back into Draft state which
    // can be confusing.

    if (dialogDefinitionAutoSave.hasPendingSave()) {
      const clonedDialogDefinition = _.cloneDeep(dialogDefinitionRef.current)
      const allElements = getAllElements(clonedDialogDefinition);
      ensureUniquePropertyNamesForAllElements(allElements);
      removeEmptyCollections(clonedDialogDefinition)

      await onSave(clonedDialogDefinition, lastUpdatedAtRef.current);
    }

    dispatch(setDialogDefinition({
      dialogDefinition: null
    }));

    return
  }

  async function load(isInitialLoad) {
    try {
      const value = await onLoad();
      dialogDefinitionRef.current = value
      await dispatch(setDialogDefinition({
        dialogDefinition: value,
        isInitialLoad,
      }));
      setSchemaErrors([]);

      const results = await getAllThemeStyle()
      setAlLStyles([...allStyles, ...results])
      setSmartformName(value?.name);
    } catch (error) {
      errorHandler.handleApiError(error, "Failed to load");
    }
  }

  const handleRemoveFromType = (type, elementId) => {
    let typeActions = actionbarAction;
    let idToRemove = elementId;

    switch (type) {
      case designContexts.container:
        typeActions = containerActions;
        idToRemove = definitionManager.findContainerFromElement(dialogDefinition, elementId)?.id
        break;
      case designContexts.row:
        typeActions = rowActions;
        idToRemove = definitionManager.findRowFromElementId(dialogDefinition, elementId)?.id
        break;
      case designContexts.column:
        typeActions = columnActions;
        idToRemove = definitionManager.findColumnFromElement(dialogDefinition, elementId)?.id
        break;
      default:
      case designContexts.element:
        typeActions = elementActions;
        break;
    }

    typeActions.remove(dialogDefinition, idToRemove);
    resetCurrentValues();
  };

  const handleDuplicateFromType = (type, elementId) => {
    let typeActions = actionbarAction;
    let toDuplicate = elementId;

    switch (type) {
      case designContexts.container:
        typeActions = containerActions;
        toDuplicate = definitionManager.findContainerFromElement(dialogDefinition, elementId)
        break;
      case designContexts.row:
        typeActions = rowActions;
        toDuplicate = definitionManager.findRowFromElementId(dialogDefinition, elementId)
        break;
      case designContexts.column:
        typeActions = columnActions;
        toDuplicate = definitionManager.findColumnFromElement(dialogDefinition, elementId)
        break;
      default:
      case designContexts.element:
        toDuplicate = definitionManager.findElement(dialogDefinition, elementId)
        typeActions = elementActions;
        break;
    }

    typeActions.duplicate(dialogDefinition, toDuplicate.id, toDuplicate);
  };

  const handleShowPropertiesFromType = (type, elementId) => {
    let typeActions = actionbarAction;
    let toDuplicate = elementId;

    switch (type) {
      case designContexts.container:
        typeActions = containerActions;
        toDuplicate = definitionManager.findContainerFromElement(dialogDefinition, elementId)
        break;
      case designContexts.row:
        typeActions = rowActions;
        toDuplicate = definitionManager.findRowFromElementId(dialogDefinition, elementId)
        break;
      case designContexts.column:
        typeActions = columnActions;
        toDuplicate = definitionManager.findColumnFromElement(dialogDefinition, elementId)
        break;
      default:
      case designContexts.element:
        toDuplicate = definitionManager.findElement(dialogDefinition, elementId)
        typeActions = elementActions;
        break;
    }

    typeActions.duplicate(dialogDefinition, toDuplicate.id, toDuplicate);
  };



  const handleRemove = () => {
    actionbarAction.remove(dialogDefinition, dataFromSelectedLayoutType().id);
    resetCurrentValues();
  };

  const saveDialogDefinition = async (saveOptions) => {
    if (dialogDefinition) {
      const errors = schemaValidator.validate(dialogDefinition);

      if (errors?.length > 0) {
        setSchemaErrors(errors);
      }
      else {
        setSchemaErrors([]);
        const clonedDialogDefinition = _.cloneDeep(dialogDefinition)
        const allElements = getAllElements(clonedDialogDefinition);
        const allContainers = getAllContainers(clonedDialogDefinition)

        const hadElementsNameCollision = ensureUniquePropertyNamesForAllElements(allElements);
        const hadContainersNameCollision = ensureUniquePropertyNamesForAllContainers(allContainers);
        const removedEmptyCollection = removeEmptyCollections(clonedDialogDefinition)
        if (hadElementsNameCollision.length > 0 || removedEmptyCollection || hadContainersNameCollision.length > 0) {
          clonedDialogDefinition.editState = editStates.draft
          dispatch(setDialogDefinition({
            dialogDefinition: clonedDialogDefinition,
            // Replace last history record because we have forced a change to prevent an
            // invalid state. Prevents having to undo twice to remove something that was added
            replaceLastHistory: true,
          }))
        }
        else {
          dispatch(setEditState(editStates.draft))
        }

        const updateResult = await onSave(clonedDialogDefinition, lastUpdatedAtRef.current);

        lastUpdatedAtRef.current = updateResult.updatedDate
        if (saveOptions?.dismissActions) {
          setOpenProperties(false);
        }
      }
    }
  };

  /**
   * Removes empty columns, rows, and containers   
   */
  const removeEmptyCollections = (dialogDefinition) => {
    let removedEmptyCollection = false
    dialogDefinition.containers?.forEach(container => {
      container.rows?.forEach(row => {
        // remove each empty column from a row
        const emptyColumns = row.columns?.filter(c => c.elements?.length < 1)
        if (emptyColumns?.length > 0) {
          removedEmptyCollection = true
          row.columns = row.columns.filter(c => !emptyColumns.includes(c))
        }
      })

      // remove each empty row from a container
      const emptyRows = container.rows?.filter(r => r.columns?.length < 1)
      if (emptyRows?.length > 0) {
        removedEmptyCollection = true
        container.rows = container.rows.filter(r => !emptyRows.includes(r))
      }
    })

    // remove each empty container from dialog definition
    const emptyContainers = dialogDefinition.containers?.filter(c => c.rows?.length < 1)
    if (emptyContainers?.length > 0) {
      removedEmptyCollection = true
      dialogDefinition.containers = dialogDefinition.containers.filter(r => !emptyContainers.includes(r))
    }

    return removedEmptyCollection
  }

  const dialogDefinitionAutoSave = useAutoSave(saveDialogDefinition, "Failed to save dialog definition");

  const setDialogDefinitionLocalHistory = useDebounceCallback((payload, additionalPayload = {}) => {
    dispatch(setDialogDefinition({
      dialogDefinition: payload,
      additionalPayload,
    }));
  }, 350);

  const save = (payload, saveOptions) => {
    dialogDefinitionRef.current = payload;
    setDialogDefinitionLocalHistory(payload, saveOptions?.reducerActionPayload);
    dialogDefinitionAutoSave.save(saveOptions);
  }

  const undo = async (postUndoCallback) => {
    await dispatch(undoDialogDefinition());
    dialogDefinitionAutoSave.save();

    if (postUndoCallback)
      postUndoCallback();
  };

  const resetNewElement = () => {
    setNewContainerId(null);
    setNewRowId(null);
    setNewColumnId(null);
    setNewElementId(null);
  };

  const setContainerContext = (newContainer) => {
    resetNewElement();
    setNewContainerId(newContainer.id);
  };

  const setRowContext = (newRow) => {
    resetNewElement();
    setNewRowId(newRow.id);
  };

  const setColumnContext = (newColumn) => {
    resetNewElement();
    setNewColumnId(newColumn.id);
  };

  const setElementContext = (newElement) => {
    resetNewElement();
    setNewElementId(newElement.id);
  };

  const handleCurrentElementChange = (newElement) => {
    setCurrentElement(newElement);
    setCurrentElementId(newElement?.id);
  }

  const definitionManager = useDefinitionManager();
  const elementActions = useElementActions(save, setElementContext, handleCurrentElementChange, themeStyleSelected?.config);
  const columnActions = useColumnActions(save, setColumnContext, themeStyleSelected?.config);
  const rowActions = useRowActions(save, setRowContext, themeStyleSelected?.config);
  const containerActions = useContainerActions(save, setContainerContext, themeStyleSelected?.config);

  const [actionbarAction, setActionbarActions] = useState(elementActions);

  useHotkeys(
    'ctrl+d, shift+del, shift+delete',
    () => handleRemove(),
    [currentElement, currentRow, currentColumn, currentContainer, actionbarAction, dialogDefinition]
  );

  useHotkeys('ctrl+z', () => undo(), [currentElement, currentRow, currentColumn, currentContainer, dialogDefinition])

  const handleGeneralChange = async (e, type = 'string') => {
    const { name, value, checked } = e.target;

    const allTypes = {
      number: +value,
      string: value,
      boolean: checked
    }

    const payload = _.cloneDeep({
      ...dialogDefinition,
      [name]: allTypes[type]
    })

    if (name === "type") {
      //change all static properties of the containers to false if the dialog type is changed to any other type
      if (parseInt(value, 10) !== dialogType.stepByStep) {
        payload.containers.forEach(container => {
          container.static && (container.static = false);
        });
      }
    }

    save(payload);
  };

  const handleThemeTypeChange = async (e, themeType, valueType = 'string') => {
    const { name, value, checked } = e.target;

    const allTypes = {
      number: +value,
      string: value,
      boolean: checked
    }

    const styleTypeKey = `${themeType}Styles`

    const payload = _.cloneDeep({
      ...dialogDefinition,
      [styleTypeKey]: {
        ...dialogDefinition[styleTypeKey],
        [name]: allTypes[valueType]
      }
    })

    save(payload);
  };

  const handleResetToDefault = () => {
    const payload = {
      ...dialogDefinition,
      backgroundColor: '#fff',
      toolbarColor: '#fff',
      margin: '0',
      padding: '0',
      type: 1,
      position: 0,
      size: 0,
      disabledPdfGeneration: false,
      themeStyleId: '0',
    }

    const defaultStyle = allStyles.find(style => style.id === '0');
    handleUpdatePageConfig(defaultStyle, payload)
  };

  const handleUpdatePageConfig = (style, payload) => {
    const dialogDefinitionCopy = _.cloneDeep(payload ? payload : dialogDefinition);

    const dialogSelectorElement = document.getElementById('dialog-selector');
    if (dialogSelectorElement) {
      const existingStyle = dialogSelectorElement.querySelector('style[CenterpointSelectedTheme="true"]');
      if (existingStyle) {
        dialogSelectorElement.removeChild(existingStyle);
      }

      const addStyle = document.createElement('style');
      addStyle.innerHTML = `@scope (#dialog-selector) to (.ignore-style-change) { ${style?.config?.css} }`;
      addStyle.setAttribute('CenterpointSelectedTheme', 'true');
      dialogSelectorElement.appendChild(addStyle);
    }
    dialogDefinitionCopy.customCss = style?.config?.css
    dialogDefinitionCopy.themeStyleId = style.id

    save(dialogDefinitionCopy);
  };

  const handleAddTemplate = (templateObject) => {
    const payload = _.cloneDeep(dialogDefinition);
    if (popoverOpenFromDesignWithContainerId) {
      definitionManager.addItemBelow(payload.containers, popoverOpenFromDesignWithContainerId, templateObject)
    }
    else if (!templateObject.container) {
      definitionManager.addItem(payload.containers, templateObject);
    }
    else {
      const templateContainer = templateObject.container;
      let cloned = _.cloneDeep(templateContainer);
      idGenerator.newIdsOnContainer(cloned);

      definitionManager.addItem(payload.containers, {
        ...cloned,
        dialogObjectId: templateObject.id,
        lockToDialogObject: true,
      })
    }

    setCurrentContainerId(templateObject.id);
    save(payload);
    onSetSelectedLayoutType(designContexts.element, selectedLayoutType)
  };

  const onActivateDialogDefinition = () => {
    activateDefinitionAction.execute(async () => {
      if (isDialogTemplate) {
        await activateDialogTemplate(dialogDefinition.id);
      } else {
        const updateResult = await activateDialogDefinition(dialogDefinition.id);
        lastUpdatedAtRef.current = updateResult.updatedDate
      }

      dispatch(setActiveStatus(dialogDefinitionStatuses.active))
    }, "Failed to activate", "Activated")
  }

  const onDeactivateDialogDefinition = () => {
    deactivateDefinitionAction.execute(async () => {
      if (isDialogTemplate) {
        await deactivateDialogTemplate(dialogDefinition.id);
      } else {
        const updateResult = await deactivateDialogDefinition(dialogDefinition.id);
        lastUpdatedAtRef.current = updateResult.updatedDate
      }

      dispatch(setActiveStatus(dialogDefinitionStatuses.inactive))
    }, "Failed to deactivate", "Deactivated")
  }

  const onActivateObjectTemplate = () => {
    activateDefinitionAction.execute(async () => {
      await activateDialogObject(objectId);
      dispatch(setActiveStatus(dialogDefinitionStatuses.active))
    }, "Failed to activate", "Activated")
  }

  const onDeactivateObjectTemplate = () => {
    deactivateDefinitionAction.execute(async () => {
      await deactivateDialogObject(objectId);
      dispatch(setActiveStatus(dialogDefinitionStatuses.inactive))
    }, "Failed to deactivate", "Deactivated")
  }

  const onPublish = () => {
    publishAction.execute(async () => {
      await saveDialogDefinition();
      // dialogDefinitionAutoSave.save();
      await publishDialogDefinition(dialogDefinition.id);
      dispatch(publish())
    }, "Failed to publish", "Changes are published");
  };

  const onShowPropertiesPanel = () => {
    resetNewElement();

    if (!isElementsTabActive()) {
      setOpenProperties(true);
      setMobileSideBarRightVisible(true)
      setMobileSideBarLeftVisible(false)
    }
  };

  const isElementsTabActive = () => {
    return location.pathname.endsWith("elements");
  };

  const setActionbarActionsWithLayoutType = (layoutType) => {
    switch (layoutType) {
      case designContexts.container:
        setActionbarActions(containerActions);
        break;
      case designContexts.row:
        setActionbarActions(rowActions);
        break;
      case designContexts.column:
        setActionbarActions({
          ...columnActions,
          addBelow: columnActions.addRight,
          addAbove: columnActions.addLeft,
        });
        break;

      case designContexts.element:
      default:
        setActionbarActions({
          ...elementActions,
          addBelow: elementActions.addRight,
          addAbove: elementActions.addLeft,
        });
        break;
    }
  };

  const setCurrentValueWithLayoutType = (newLayoutType, beforeLayoutType = designContexts.element) => {
    switch (newLayoutType) {
      case designContexts.element:
        if (beforeLayoutType === designContexts.row && currentRow) {
          definitionManager.openFirstElementOfRowActionBar(currentRow);
        } else if (beforeLayoutType === designContexts.column && currentColumn) {
          definitionManager.openFirstElementOfColumnActionBar(currentColumn);
        } else {
          resetCurrentValues();
        }
        break;

      case designContexts.row:
        if (beforeLayoutType === designContexts.element && currentElementId) {
          definitionManager.openRowOfElementActionBar(dialogDefinition, currentElementId);
        } else if (beforeLayoutType === designContexts.column && currentColumnId) {
          definitionManager.openRowOfColumnActionBar(dialogDefinition, currentColumnId);
        } else {
          resetCurrentValues();
        }
        break;

      case designContexts.column:
        if (beforeLayoutType === designContexts.element && currentElementId) {
          definitionManager.openColumnOfElementActionBar(dialogDefinition, currentElementId);
        } else if (beforeLayoutType === designContexts.row && currentRow) {
          definitionManager.openFirstColumnOfRowActionBar(currentRow);
        } else {
          resetCurrentValues();
        }
        break;

      default:
        // resetCurrentValues();
        break;
    }
  }

  const onSetSelectedLayoutType = (layoutType, beforeLayoutType) => {
    setActionbarActionsWithLayoutType(layoutType)
    setCurrentValueWithLayoutType(layoutType, beforeLayoutType)
    setSelectedLayoutType(layoutType);
  };

  const changeLayoutType = (layoutType) => {
    // onSetSelectedLayoutType(layoutType, selectedLayoutType)
  }

  const dndHook = useDndHook(save, columnActions, elementActions, containerActions, rowActions, changeLayoutType)

  const onSetSelectedZoom = (value) => {
    setSelectedZoom(value)
  }

  const onSetIsMobileView = (value) => {
    setIsMobileView(value)
  }

  const resetCurrentValues = () => {
    setCurrentContainer(null);
    setCurrentContainerId(null);
    setCurrentRow(null);
    setCurrentRowId(null);
    setCurrentColumn(null);
    setCurrentColumnId(null);
    // setCurrentElement(null);
    // setCurrentElementId(null);
  };


  const dataFromSelectedLayoutType = () => {
    switch (selectedLayoutType) {
      case designContexts.container:
        return currentContainer;
      case designContexts.row:
        return currentRow;
      case designContexts.column:
        return currentColumn;
      case designContexts.element:
      default:
        return currentElement;
    }
  };

  const handleShowGridClick = () => {
    setShowGrid(!showGrid);
  };

  const findElementTypeKeyByValue = (value) => {
    for (const key in elementTypes) {
      if (elementTypes[key] === value) {
        return key;
      }
    }
    return undefined;
  }

  const buildPropertyWithValidation = (payload, element) => {
    const elementsOfType = definitionManager.elementsOfTypeFromDialogDefinition(payload, element.type);
    const elementIndexOfProperty = elementsOfType.findIndex(e => !element.property ? !e.property : e.property === element.property);
    const elementTypeName = findElementTypeKeyByValue(element.type);
    let elementLengthWithValidation = element.id.substring(0, 4);

    if (elementIndexOfProperty !== -1) {
      elementLengthWithValidation = elementIndexOfProperty + 1;

    } else if (!element.property) {
      elementLengthWithValidation = elementsOfType.length + 1;
    }

    element.property = `${elementTypeName}-${elementLengthWithValidation}`;
  }


  const handleAddElement = (element) => {
    let payload = _.cloneDeep(dialogDefinition);
    buildPropertyWithValidation(payload, element)

    switch (selectedLayoutType) {
      case designContexts.element:
        if (currentElement && containersLength) {
          let column = definitionManager.findColumnFromElement(payload, currentElementId);
          if (!column) {
            column = definitionManager.findLastColumnForDialogDefinition(payload)
          }

          definitionManager.addItem(column.elements, element);
        }
        else {
          payload = addElementToLastContainer(payload, element);
        }

        break;
      case designContexts.column:
        if (currentColumn && containersLength) {
          const column = definitionManager.findColumn(payload, currentColumn.id);
          definitionManager.addItem(column.elements, element);
        }
        else {
          payload = addElementToLastContainer(payload, element);
        }
        break;
      case designContexts.row:
        if (currentRow && containersLength) {
          const row = definitionManager.findRow(payload, currentRow.id);
          let lastColumn = definitionManager.findLastColumn(row);
          definitionManager.addItem(lastColumn.elements, element);
        }
        else {
          payload = addElementToLastContainer(payload, element);
        }
        break;
      default:
      case designContexts.container:
        if (currentContainer && containersLength) {
          const container = definitionManager.findContainer(payload, currentContainer.id);
          let lastRow = definitionManager.findLastRow(container);
          let lastColumn = definitionManager.findLastColumn(lastRow);
          definitionManager.addItem(lastColumn.elements, element);
        }
        else {
          payload = addElementToLastContainer(payload, element);
        }
        break;
    }

    setElementContext(element);
    save(payload);

    setCurrentElementId(element.id);
    setCurrentElement(element);
    onSetSelectedLayoutType(designContexts.element, selectedLayoutType)
    // definitionManager.openElementActionBar(element);
  };

  const handleAddContainer = (afterOfContainerId, containerTemplate) => {

    const payload = _.cloneDeep(dialogDefinition);
    if (selectedLayoutType === designContexts.container && currentContainer) {
      containerActions.addBelow(payload, currentContainer.id);
    }
    else if (afterOfContainerId && containerTemplate) {
      containerActions.addTemplateBelow(payload, afterOfContainerId, containerTemplate);
    }
    else if (afterOfContainerId) {
      containerActions.addBelow(payload, afterOfContainerId);
    }
    else {
      let lastContainer = definitionManager.findLastContainer(payload);
      if (lastContainer) {
        containerActions.addBelow(payload, lastContainer.id);
      }
      else {
        if (containerTemplate) {
          containerActions.addTemplateToEmpty(payload, containerTemplate);
        } else {
          containerActions.addToEmpty(payload);
        }

      }
    }
  };

  const handleAddRow = () => {
    const payload = _.cloneDeep(dialogDefinition);
    switch (selectedLayoutType) {
      case designContexts.element:
        if (currentElement && containersLength) {
          let column = definitionManager.findColumnFromElement(payload, currentElement.id);
          if (!column) {
            column = definitionManager.findLastColumnForDialogDefinition(payload)
          }

          const row = definitionManager.findRowFromColumn(payload, column.id);
          rowActions.addBelow(payload, row.id);
        }
        else {
          addRowToEndOfLastContainer(payload);
        }
        break;
      case designContexts.column:
        if (currentColumn && containersLength) {
          const row = definitionManager.findRowFromColumn(payload, currentColumn.id);
          rowActions.addBelow(payload, row.id);
        }
        else {
          addRowToEndOfLastContainer(payload);
        }
        break;
      case designContexts.row:
        if (currentRow && containersLength) {
          rowActions.addBelow(payload, currentRow.id);
        }
        else {
          addRowToEndOfLastContainer(payload);
        }
        break;
      default:
      case designContexts.container:
        if (currentContainer && containersLength) {
          let lastRow = definitionManager.findLastRow(currentContainer);
          if (lastRow) {
            rowActions.addBelow(payload, lastRow.id);
          }
          else {
            const container = definitionManager.findContainer(payload, currentContainer.id);
            if (container)
              containerActions.addRow(payload, container);

            else
              containerActions.addRowAndNewContainer(payload);
          }
        }
        else {
          addRowToEndOfLastContainer(payload);
        }
        break;
    }
  };

  const addRowToEndOfLastContainer = (dDefinition) => {
    const lastContainer = definitionManager.findLastUnlockedConatiner(dDefinition);

    if (lastContainer) {
      const lastRow = definitionManager.findLastRow(lastContainer);
      if (lastRow) {
        rowActions.addBelow(dDefinition, lastRow.id);
      } else {
        containerActions.addRow(dDefinition, lastContainer);
      }
    }
    else {
      containerActions.addToEmpty(dDefinition);
    }
  };

  const handleAddColumn = () => {
    const payload = _.cloneDeep(dialogDefinition);
    switch (selectedLayoutType) {
      case designContexts.element:
        if (currentElement && containersLength) {
          let column = definitionManager.findColumnFromElement(payload, currentElement.id);
          if (!column) {
            column = definitionManager.findLastColumnForDialogDefinition(payload)
          }
          columnActions.addRight(payload, column.id);
        }
        else {
          addColumnToEndOfLastContainer(payload);
        }
        break;
      case designContexts.column:
        if (currentColumn && containersLength) {
          columnActions.addRight(payload, currentColumn.id);
        }
        else {
          addColumnToEndOfLastContainer(payload);
        }
        break;
      case designContexts.row:
        if (currentRow && containersLength) {
          const row = definitionManager.findRow(payload, currentRow.id);
          rowActions.addColumn(payload, row);
        }
        else {
          addColumnToEndOfLastContainer(payload);
        }
        break;
      default:
      case designContexts.container:
        if (currentContainer && containersLength) {
          const container = definitionManager.findContainer(payload, currentContainer.id);
          let lastRow = definitionManager.findLastRow(container);
          if (lastRow) {
            const lastColumn = definitionManager.findLastColumn(lastRow);
            if (lastColumn) {
              columnActions.addRight(payload, lastColumn.id);
            }
            else {
              // if row doesn't have columnns, add a column to the row
              rowActions.addColumn(payload, lastRow);
            }
          }
          else {
            // if container has no row, add a row which will add a default row
            // that has a column
            containerActions.addRow(payload, container);
          }
        }
        else {
          addColumnToEndOfLastContainer(payload);
        }
        break;
    }
  };

  const addColumnToEndOfLastContainer = (definition) => {
    const lastContainer = definitionManager.findLastUnlockedConatiner(definition);
    if (lastContainer) {
      const lastRow = definitionManager.findLastRow(lastContainer);
      if (lastRow) {
        const lastColumn = definitionManager.findLastColumn(lastRow);
        if (lastColumn) {
          columnActions.addRight(definition, lastColumn.id);
        }
      }
      else {
        containerActions.addRow(definition, lastContainer);
      }
    }
    else {
      containerActions.addToEmpty(definition);
    }
  };

  const addElementToLastContainer = (definition, element) => {
    const lastContainer = definitionManager.findLastUnlockedConatiner(definition);
    if (lastContainer) {
      let lastRow = definitionManager.findLastRow(lastContainer);
      let lastColumn = definitionManager.findLastColumn(lastRow);
      definitionManager.addItem(lastColumn.elements, element);
      return definition;
    }
    else {
      const newContainer = containerActions.addEmptyWithElement(definition, element);
      setContainerContext(newContainer);
      return newContainer
    }
  };

  const backgroundColorStyle = dialogDefinition?.backgroundColor ? dialogDefinition.backgroundColor : '#fff'
  const style = {
    backgroundColor: backgroundColorStyle,
  }

  /**
   * https://github.com/clauderic/dnd-kit/issues/591
   * Put a minimum drag distance to prevent an accidental tiny drag from activating the drag event and preventing
   * any other click events
   */
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4,
      },
    })
  )

  const handleSelectLayoutType = (e, layoutType, close = () => null) => {
    e.stopPropagation();

    close();

    switch (layoutType) {
      case designContexts.container:
        setActionbarActions(containerActions)
        setCanMoveHorizontal(false)
        setCanMoveVertical(true)
        break;
      case designContexts.row:
        setActionbarActions(rowActions)
        setCanMoveHorizontal(false)
        setCanMoveVertical(true)
        break;
      case designContexts.column:
        setActionbarActions(columnActions)
        setCanMoveHorizontal(true)
        setCanMoveVertical(false)
        break;
      case designContexts.element:
      default:
        setActionbarActions(elementActions)
        setCanMoveVertical(true)
        setCanMoveHorizontal(true)
        break;
    }
  }

  const onDragOver = (e) => {
    !showAllGrid && setShowAllGrid(true)
    dndHook.handleDragOver(e)
  }

  const onDragStart = (e) => {
    setShowAllGrid(true)
    dndHook.handleDragStarted(e)
  }

  const onDragCancel = (e) => {
    setShowAllGrid(false)
    dndHook.handleDragCancelled(e)
  }

  const onDragEnd = (e) => {
    setShowAllGrid(false)
    dndHook.handleDragEnd(e)
  }

  const setBadgeSelectedTypeDebounce = useDebounceCallback((type) => {
    setBadgeSelectedType(type)
  }, 200);

  const setSmartformNameDebounce = useDebounceCallback((value) => {
    smartformNameAction.execute(async () => {
      await updateDialogDefinitionProperties(dialogKey, { ...dialogDefinition, category: dialogDefinition.metaData['Category'], name: value })
    }, "Failed to change new Smartform name", "Smartform name has been changed")
  }, 1000);

  const handleSmartformName = (event) => {
    const inputValue = event.target.value;
    setSmartformName(inputValue)
    setSmartformNameDebounce(inputValue)
  }

  const setMultipleDialogDefinitionValues = (keyValues) => {
    setMultipleDialogDefinitionValuesDebounce({ ...dialogDefinition, ...keyValues })
    dispatch(setDialogDefinition({
      dialogDefinition: { ...dialogDefinition, ...keyValues }
    }));
  }

  const setMultipleDialogDefinitionValuesDebounce = useDebounceCallback((values) => {
    multipleValuesAction.execute(async () => {
      await updateDialogDefinitionProperties(dialogKey, { ...dialogDefinition, ...values })
    }, "Failed to save new changes", "Save new changes")
  }, 1000);

  return (
    <>
      <ElementContext.Provider
        value={{
          dialogDefinition,
          designElementForContextMenu,
          setDesignElementForContextMenu,
          showPropertiesPanel: (elementId, element, isLocked, propertiesOnElement, openPanel) => {
            actionBarSelectedElementIdRef.current = elementId
            setActionBarSelectedElementId(elementId)
            setCurrentElementId(elementId);
            setCurrentElement(element);
            setPropertiesToShow(propertiesOnElement);
            setIsClickedThingLocked(isLocked)
            onShowPropertiesPanel();
            if (openPanel) {
              setActiveTabRight(sidebarRightTabs.properties);
            }
          },
          removeSelectedActionBarElement: (elementId) => {
            // Only unset action bar's elementId if the passed in elementId is the same as the 
            // action bar's elementId
            if (elementId === actionBarSelectedElementIdRef.current) {
              actionBarSelectedElementIdRef.current = null
              setActionBarSelectedElementId(null)
            }
          },
          handleSelectLayoutType,
          setBadgeSelectedType: setBadgeSelectedTypeDebounce,
          selectedLayoutType,
          currentElementId: currentElementId || null,
          actionBarSelectedElementId: selectedLayoutType === designContexts.element ? actionBarSelectedElementId : null,
          currentElement: currentElement || null,
          enabled: selectedLayoutType === designContexts.element,
          actions: elementActions,
          newElementId: newElementId,
          showGrid: showGrid,
          process: dialogDefinition?.process,
          themeStyleSelected,
          showAllGrid,
          showRowOnDoubleClick,
          setShowRowOnDoubleClick,
          showRightClickMenu,
          setShowRightClickMenu,
          rightClickMenuPosition,
          setRightClickMenuPosition
        }}
      >
        <RowContext.Provider
          value={{
            showPropertiesPanel: (rowId, row, isLocked, propertiesOnRow, openPanel = true) => {
              setCurrentRowId(rowId);
              setCurrentRow(row);
              setPropertiesToShow(propertiesOnRow);
              setIsClickedThingLocked(isLocked)
              onShowPropertiesPanel();

              if (openPanel) {
                setActiveTabRight(sidebarRightTabs.properties);
              }
            },
            setCurrent: (rowId, row) => {
              setCurrentRowId(rowId);
              setCurrentRow(row);
            },
            selectedLayoutType: selectedLayoutType,
            currentRow: selectedLayoutType === designContexts.row && currentRow ? currentRow : null,
            currentRowId: currentRowId,
            enabled: selectedLayoutType === designContexts.row,
            actions: rowActions,
            newRowId: newRowId,
            showGrid: showGrid,
            showAllGrid
          }}
        >
          <ColumnContext.Provider
            value={{
              showPropertiesPanel: (columnId, column, isLocked, propertiesOnColumn, openPanel = false) => {
                setCurrentColumnId(columnId);
                setCurrentColumn(column);
                setPropertiesToShow(propertiesOnColumn);
                setIsClickedThingLocked(isLocked)
                onShowPropertiesPanel();

                if (openPanel) {
                  setActiveTabRight(sidebarRightTabs.properties);
                }
              },
              selectedLayoutType,
              currentColumn: selectedLayoutType === designContexts.column && currentColumn ? currentColumn : null,
              currentColumnId: currentColumnId,
              enabled: selectedLayoutType === designContexts.column,
              actions: columnActions,
              newColumnId: newColumnId,
              showGrid: showGrid,
              showAllGrid
            }}
          >
            <ContainerContext.Provider
              value={{
                showPropertiesPanel: (containerId, container, isLocked, propertiesOnContainer) => {
                  setCurrentContainerId(containerId);
                  setCurrentContainer(container);
                  setPropertiesToShow(propertiesOnContainer);
                  setIsClickedThingLocked(isLocked)
                  onShowPropertiesPanel();
                  setActiveTabRight(sidebarRightTabs.properties)
                },
                removePropertiesPanel: () => {
                  resetCurrentValues()
                  setPropertiesToShow()
                  setIsClickedThingLocked(false)
                },
                onHover: (containerId, container, isLocked) => {
                  if (currentContainerId !== containerId) {
                    //setOpenProperties(false)
                  }
                  setCurrentScrollPositionTop(mainDialogRef?.current.scrollTop)
                  setCurrentContainerId(containerId);
                  setCurrentContainer(container);
                  //setIsClickedThingLocked(isLocked)
                },
                currentContainer: currentContainer ? currentContainer : null,
                currentContainerId: currentContainerId ? currentContainerId : null,
                newContainerId: newContainerId,
                enabled: selectedLayoutType === designContexts.container,
                actions: containerActions,
                showGrid: showGrid,
                isObjectTemplate,
                isDialogTemplate,
                themeStyleSelected,
                showAllGrid
              }}
            >
              <>
                {dialogDefinition &&
                  <>
                    <DesignActionbarContext.Provider
                      value={{
                        undo: undo,
                        actions: actionbarAction,
                        onSetSelectedLayoutType: onSetSelectedLayoutType,
                        selectedLayoutType: selectedLayoutType,
                        data: dataFromSelectedLayoutType(),
                        onShowGridClick: handleShowGridClick,
                        onPublish: onPublish,
                        publishExecuting: publishAction.isExecuting,
                        onActivate: isObjectTemplate ? onActivateObjectTemplate : onActivateDialogDefinition,
                        activateExecuting: activateDefinitionAction.isExecuting,
                        onDeactivate: isObjectTemplate ? onDeactivateObjectTemplate : onDeactivateDialogDefinition,
                        deactivateExecuting: deactivateDefinitionAction.isExecuting,
                        dialogDefinition: dialogDefinition,
                        handleGeneralChange: handleGeneralChange,
                        handleThemeTypeChange: handleThemeTypeChange,
                        handleResetToDefault: handleResetToDefault,
                        handleUpdatePageConfig: handleUpdatePageConfig,
                        handleAddTemplate: handleAddTemplate,
                        handleAddElement: handleAddElement,
                        handleRemove: handleRemove,
                        handleRemoveFromType,
                        handleDuplicateFromType,
                        handleShowPropertiesFromType,
                        handleAddContainer,
                        handleAddRow,
                        handleAddColumn,
                        handleSelectLayoutType,
                        handleSmartformName,
                        setMultipleDialogDefinitionValues,
                        smartformName,
                        definitionManager,
                        openObjects,
                        isCurrentThingLocked: isClickedThingLocked,
                        disableAddObjects: disableAddObjects,
                        disableExport: disableExport,
                        disableAddElements: disableAddElements,
                        disableAddContainer: disableAddContainer,
                        disableAddRow: disableAddRow,
                        disableAddColumn: disableAddColumn,
                        disableSettings: disableSettings,
                        disablePublish: disablePublish,
                        isDialogTemplate,
                        isObjectTemplate,
                        setActiveTab: setActiveTabRight,
                        allStyles: allStyles,
                        themeStyleSelected,
                        confirmRemoveModalHelper,
                        setTypeToRemove,
                        canAdd: () => {
                          return !isClickedThingLocked
                        },
                        canDuplicate: () => {
                          if (disableDuplicateContainer && selectedLayoutType === designContexts.container) {
                            return false
                          }

                          const data = dataFromSelectedLayoutType()
                          return data && !isClickedThingLocked
                        },
                        setCanMoveVertical,
                        setCanMoveHorizontal,
                        setOpenObjects: (isOpen, containerId) => {
                          setPopoverOpenFromDesignWithCointainerId(containerId)
                          setOpenObjects(isOpen)
                        },
                        popoverOpenFromDesignWithContainerId: popoverOpenFromDesignWithContainerId,
                        removeDisabled: () => {
                          if (isClickedThingLocked && selectedLayoutType !== designContexts.container) {
                            return true
                          }
                          return !dataFromSelectedLayoutType()
                        },
                        canMoveLeft: () => {
                          const data = dataFromSelectedLayoutType()
                          if (selectedLayoutType === designContexts.row) {
                            return false
                          }
                          if (isClickedThingLocked && selectedLayoutType !== designContexts.container) {
                            return false
                          }
                          if (!data || !canMoveHorizontal) {
                            return false
                          }
                          if (selectedLayoutType !== designContexts.element) {
                            return true
                          }
                          if (!actionbarAction.canMoveLeft) {
                            return false
                          }
                          return actionbarAction.canMoveLeft(dialogDefinition, data.id)
                        },
                        canMoveRight: () => {
                          const data = dataFromSelectedLayoutType()
                          if (selectedLayoutType === designContexts.row) {
                            return false
                          }
                          if (isClickedThingLocked && selectedLayoutType !== designContexts.container) {
                            return false
                          }
                          if (!data || !canMoveHorizontal) {
                            return false
                          }
                          if (selectedLayoutType !== designContexts.element) {
                            return true
                          }
                          if (!actionbarAction.canMoveRight) {
                            return false
                          }
                          return actionbarAction.canMoveRight(dialogDefinition, data.id)
                        },
                        canMoveVertical: () => {
                          const data = dataFromSelectedLayoutType()
                          if (isClickedThingLocked && selectedLayoutType !== designContexts.container) {
                            return false
                          }
                          return data && canMoveVertical
                        },
                        onSetSelectedZoom: onSetSelectedZoom,
                        onSetIsMobileView: onSetIsMobileView,
                      }}
                    >
                      <GlobalDndContextWrapper
                        dndHook={dndHook}
                      >
                        <DragDropContext
                          onDragEnd={dndHook.onReactBeautifulDndDragEnd}
                          onDragStart={dndHook.onReactBeautifulDndDragStart}
                          onBeforeDragStart={() => false}
                        >
                          <DndContext
                            sensors={sensors}
                            measuring={{
                              droppable: {
                                strategy: MeasuringStrategy.Always,
                              },
                            }}
                            onDragStart={onDragStart}
                            onDragCancel={onDragCancel}
                            onDragOver={onDragOver}
                            onDragEnd={onDragEnd}
                          >
                            <div className="relative flex-1 flex w-full h-full gap-12 mt-4 pr-4 xl:pr-0">
                              <aside className="absolute xl:static -right-6 xl:flex xl:flex-col xl:flex-shrink-0 xl:w-1/5 shadow-xl max-w-9/10screen" style={{ zIndex: 9991 }}>
                                {/* Start secondary column (hidden on smaller screens) */}
                                <SidebarLeft
                                  activeTab={activeTabLeft}
                                  setActiveTab={setActiveTabLeft}
                                  propertiesToShow={propertiesToShow}
                                  schemaErrors={schemaErrors}
                                  dialogDefinition={dialogDefinition}
                                  data={dataFromSelectedLayoutType()}
                                  isMobileVisible={mobileSideBarLeftVisible}
                                  setIsMobileVisible={setMobileSideBarLeftVisible} />
                                {/* End secondary column */}
                              </aside>
                              <main ref={mainDialogRef} className="flex-1 h-full relative overflow-y-auto overflow-x-auto md:overflow-x-hidden shadow-xl" style={style}>
                                <div className='sticky top-0 px-4 pb-2 pt-2 border-b flex border-gray-200 justify-between space-x-2 items-center bg-white' style={{ zIndex: 999 }}>
                                  <textarea
                                    row={0}
                                    className={`bg-transparent resize-none border-0 px-0 py-0 w-1/3 h-7`}
                                    name="smartformName"
                                    value={smartformName}
                                    onChange={handleSmartformName}
                                  />
                                  <div className='w-1/3'>
                                    <DialogPageViewSelect disableDatasets={disableDatasets} activePage={activeDesignerPage} onPageClick={pageKey => setActiveDesignerPage(pageKey)} />
                                  </div>
                                  <div className='w-1/3 flex justify-end'>
                                    <div>
                                      {
                                        {
                                          'element': <Badge type="default" text="Element" />,
                                          'column': <Badge type="default" text="Column" />,
                                          'row': <Badge type="default" text="Row" />,
                                          'section': <Badge type="default" text="Section" />,
                                        }[badgeSelectedType]
                                      }
                                    </div>
                                  </div>
                                </div>

                                {/* Start main area*/}
                                <div className="dialog-design-container relative">
                                  <div className="flex justify-between px-5 py-5 print:p-0" style={{ backgroundColor: dialogDefinition.toolbarColor }} />
                                  {activeDesignerPage === dialogDesignerPageKeys.html ?
                                    (<>{
                                      isMobileView ?
                                        <MobileView>
                                          <Structures.Dialog
                                            data={dialogDefinition}
                                            zoom={selectedZoom}
                                            isMobileMode={isMobileView}
                                            isEditMode
                                            activeDragFromSidbarType={dndHook.hasActiveDrag() && dndHook.activeFromSidebar && dndHook.activeDndType}
                                          />
                                        </MobileView>
                                        :
                                        <Structures.Dialog
                                          data={dialogDefinition}
                                          zoom={selectedZoom}
                                          isMobileMode={isMobileView}
                                          isEditMode
                                          activeDragFromSidbarType={dndHook.hasActiveDrag() && dndHook.activeFromSidebar && dndHook.activeDndType}
                                        />
                                    }</>) :
                                    activeDesignerPage === dialogDesignerPageKeys.data ? (
                                      <DialogDataset dialogDefinitionId={dialogDefinition.id}/>
                                    ) :
                                      activeDesignerPage === dialogDesignerPageKeys.pdf ?
                                        (<div>pdf</div>) :(<></>)
                                        
                                  }

                                </div>
                                {/* End main area */}
                              </main>

                              {/* <aside className='absolute -right-6 xl:static xl:flex xl:flex-col xl:flex-shrink-0 xl:w-1/5 shadow-xl' style={{ zIndex: 9991 }}>
                                <div className="bg-gray-50 h-full w-full">d</div>
                              </aside> */}
                              <aside className="absolute -right-6 xl:static xl:flex xl:flex-col xl:flex-shrink-0 xl:w-1/5 shadow-xl max-w-9/10screen" style={{ zIndex: 9991 }}>
                                {/* Start secondary column (hidden on smaller screens) */}

                                <SidebarRight
                                  activeTab={activeTabRight}
                                  setActiveTab={setActiveTabRight}
                                  propertiesToShow={propertiesToShow}
                                  schemaErrors={schemaErrors}
                                  dialogDefinition={dialogDefinition}
                                  data={dataFromSelectedLayoutType()}
                                  disabled={isMobileView}
                                  isMobileVisible={mobileSideBarRightVisible}
                                  setIsMobileVisible={setMobileSideBarRightVisible}
                                />
                                {/* <HelperSidebar /> */}
                                {/* End secondary column */}

                              </aside>
                              {(!mobileSideBarLeftVisible && !mobileSideBarRightVisible) && (
                                <>
                                  <MobileRightSidebarToggle onSidebarToggle={() => setMobileSideBarRightVisible(prev => !prev)} />
                                  <MobileLeftSidebarToggle onSidebarToggle={() => setMobileSideBarLeftVisible(prev => !prev)} />
                                </>
                              )}
                            </div>
                            {
                              !isMobileView &&
                              <ElementRightClickMenu open={false} setMobileSideBarRightVisible={setMobileSideBarRightVisible} setMobileSideBarLeftVisible={setMobileSideBarLeftVisible} />
                            }

                            <Confirm
                              modalHelper={confirmRemoveModalHelper}
                              title={`You want to delete the ${typeToRemove}?`}
                              onConfirm={() => handleRemoveFromType(typeToRemove, currentElement.id)}
                            />

                            <DragOverlay>
                              {
                                dndHook.hasActiveDrag() && dndHook.activeFromSidebar ?
                                  <div className='bg-white shadow sm:rounded-lg flex justify-around items-center w-14 h-14' style={{ cursor: 'grabbing' }}>
                                    <ArrowsExpandIcon className={`transform rotate-45 h-5 w-5 group-hover:text-gray-400`} />
                                  </div>
                                  : null
                              }
                            </DragOverlay>

                          </DndContext>
                        </DragDropContext>
                      </GlobalDndContextWrapper>
                    </DesignActionbarContext.Provider>
                  </>}
              </>

            </ContainerContext.Provider>
          </ColumnContext.Provider>
        </RowContext.Provider>
      </ElementContext.Provider>
    </>
  );
}
