import { useContext, useEffect, useRef, useState } from 'react'
import { DialogModes, FormContext } from '../../contexts'
import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle'
import { useInlineEditing } from '../../hooks/useInlineEditing';
import InlineEditing from '../../components/InlineEditing';
import { OpenElementProperties } from '../../utils/elements';
import { getDialogValueKey } from '../../utils/features';
import { useDebounceCallback } from '@react-hook/debounce';
import { getInformationWorkflow } from '../../api/apiDefinition';

import autosize from 'autosize'
import { useParams } from 'react-router';

const defaultInputTextLineData = {
  type: 2,
  inputType: 1,
  property: 'Email',
  value: '',
  maxLength: 8,
  placeholder: 'you@example.com',
  layout: {
    order: 4,
  },
  dependencies: {
    type: 0,
    value: 'none',
  },
  styles: {
    borderWidth: 1
  },
  text: {
    alignment: 'left',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
    color: 'grey'
  }
}

export default function TextArea({ data, ...props }) {
  data = data || defaultInputTextLineData;
  const formContext = useContext(FormContext);
  const { isEditMode } = useContext(DialogModes);
  const elementDesign = useElementDesign(data.id, data)
  const elementStyle = useElementStyle(data);
  const multiStep = useMultiStep(data);
  const classPrefix = "textarea"
  const [inputValue, setInputValue] = useState(data?.initialValue || '');
  const [rows, setRows] = useState(data?.rows || 4)
  const { dialogKey, valuesKey } = useParams();

  const isLabelHidden = data?.hideLabel ?? false; 
  const labelStyle = elementStyle.textStyle(data?.labelStyle);
  const labelClass = elementStyle.textClassName(data?.labelStyle);
  const textStyle = elementStyle.textStyle(data?.text);
  const textClass = elementStyle.textClassName(data?.text);
  const { borderWidth, borderColor } = elementStyle.borderAndWidthStyle();

  let colorClass = data?.text?.color ? `${classPrefix}--color-${data.text.color} ` : ''
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, formContext?.inputValues[getDialogValueKey(data)]?.value)
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'


  const style = {
    ...textStyle,
    borderWidth: !inputErrorClasses ? borderWidth : undefined,
    borderColor: !inputErrorClasses ? borderColor : undefined,
    lineHeight : 1.4,
    minHeight: '2rem',
    backgroundColor: backgroundColorStyle
  }

  const inputRef = useRef();
  const inputName = isEditMode ? 'initialValue' : ''
  const inputProps = isEditMode ? { onClick: (e) => OpenElementProperties(e, elementDesign.onClick) } : {}
  const { onChangeInlineEditing } = useInlineEditing(data)

  const isReadOnlyDependency = elementDesign.isReadOnlyDependency(data?.dependencies?.value, data?.dependencies?.type);

  if(isReadOnlyDependency) {
    inputRef.current.value = '';
  }

  let onChange
  if(formContext){
    onChange = (e) => {
      formContext.updateValue(getDialogValueKey(data), e.target.value);
      formContext?.reusablePropertyChanged(data.property, e.target.value)
      if (data.workflowRetrieveId)
      getInformationFromWorkflow(e.target.value, data.workflowRetrieveId);
    }
  } else {
    onChange = (e) => {
      if(isEditMode) onChangeInlineEditing(e)
      setInputValue(e.target.value)
      if (data.workflowRetrieveId)
      getInformationFromWorkflow(e.target.value, data.workflowRetrieveId);
    }
  }

  useEffect(() => {
    autosize(inputRef.current)
  }, [])

  useEffect(() => {
    setInputValue(data.initialValue ?? '')
    formContext?.reusablePropertyChanged(data.property, formContext?.inputValues[getDialogValueKey(data)]?.value)
  }, [data]);

  const getInformationFromWorkflow = useDebounceCallback((value, workflowRetrieveId) => {
    const execute = async () => {
      var response = await getInformationWorkflow(dialogKey, valuesKey, workflowRetrieveId, [{ property: data.property, value: value }])
      response.forEach(element => {
        if(!element?.preventDataInsert)
          formContext?.updateByPropertyNameValue(element.property, element.value)
      });
    }
    execute();
  },
    500
  );

  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div
        className={`${elementDesign.elementsClasses()} ${data?.labelTop ? '' : 'flex'}`}
        onClick={elementDesign.onClick}
      >
        <div className={`${isLabelHidden ? 'hidden' : ''}`} style={labelStyle}> 
          <InlineEditing
            initData={data}
            style={labelStyle}
            classes={`sf-input-text-area-label flex text-sm text-gray-700 sm:mt-px sm:pt-2 break-words ${labelClass}`}
            name='label'
            id={`label-${data.id}`}
          >
            <label className={`sf-input-text-area-label flex text-sm text-gray-700 sm:mt-px sm:pt-2 break-words ${labelClass}`} style={labelStyle}>
              {elementDesign.translateTerm(data.label)}
              {elementDesign.requiredFieldIndicator(data.requiredField)}
              {elementDesign.translateHelpText(data)}
            </label>
          </InlineEditing>
        </div>
        <div className="mt-2 sm:mt-0 w-full">
          <textarea
            ref={inputRef}
            id={data.property}
            name={inputName}
            value={formContext ? formContext?.inputValues[getDialogValueKey(data)]?.value : inputValue}
            onChange={onChange}
            rows={rows}
            placeholder={elementDesign.translateTerm(data.placeholder, 'placeholder')}
            className={`sf-input-text-area-input element block w-full ${classPrefix} ${roundedCornerClass} ${colorClass} ${inputErrorClasses} ${textClass} ${elementDesign.readOnlyElementClasses()}`}
            style={style}
            onBlur={elementDesign.handleBlur}
            disabled={elementDesign.isReadOnly() || multiStep.isReadOnly || isReadOnlyDependency}
            {...inputProps}
          />
        </div>
      </div>
    </InlineActionbarElementWrapper>
  )
}
