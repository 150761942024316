import { useContext } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { GlobalDndContext } from '../../contexts';

import { DragHandle } from './dragHandle';
import { designContexts } from '../../utils/constants';

/**
 * @typedef {Object} Props
 * @prop {string} className
 * @prop {string} id
 * @prop {('container'|'row'|'column'|'element')} type
 * @prop {string} name
 * @prop {boolean} dragHandle
 * @prop {string} dragHandleTooltip
 */

/**
 * Sortable component for dnd-kit 
 * @param {Props} param0 
 * @returns 
 */
export const DndSortable = ({
  className,
  children,
  id,
  type,
  name = '',
  dragHandle,
  dragHandleTooltip,
  fromSidebar,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    over,
    active,
    isOver,
    isDragging
  } = useSortable({
    id, data: {
      type,
      name
    }
  });
  const globalDndContext = useContext(GlobalDndContext)
  const isDragTypeEnabled = globalDndContext.isTypeEnabled(type)
  const containersLength = globalDndContext.getContainers()?.length

  let dndProps = isDragTypeEnabled
    ? { ...attributes, ref: setNodeRef }
    : {}
  dndProps = isDragTypeEnabled && !dragHandle
    ? { ...dndProps, ...listeners }
    : dndProps

  transform?.scaleY && (transform.scaleY = 1)
  transform?.scaleX && (transform.scaleX = 1)

  const isActive = attributes['aria-pressed']

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }


  if (dragHandle) {
    return (
      <div
        className={`${className} ${isActive ? 'border-2 border-red-600 z-40' : ''}`}
        style={style}
        {...dndProps}
        id={`active-${id}`}
      >
        <DragHandle
          id={id}
          isHandleVisible={isDragTypeEnabled}
          dragHandleProps={listeners}
          forSortableType={type}
          tooltip={dragHandleTooltip}
        >
          {children}
        </DragHandle>
      </div>
    )
  }

  return (
    <div
      style={style}
      {...dndProps}
      className={`${isActive ? 'border-2 border-red-600 z-40' : ''}`}
    >
      {children}
    </div>
  );
}