import { destroy, get, post, put } from './api'

export const getDialogDefinitionDatasets = async (dialogDefinitionId) => {
    return await get(`dialogDefinitions/${dialogDefinitionId}/datasets`);
}

export const getDialogDefinitionDataset = async (dialogDefinitionId, id) => {
    return await get(`dialogDefinitions/${dialogDefinitionId}/datasets/${id}`);
}

export const createDialogDefinitionDataset = async (dialogDefinitionId, name) => {
    return await post(`dialogDefinitions/${dialogDefinitionId}/datasets`, { name });
}

export const updateDialogDefinitionDataset = async (dialogDefinitionId, id,dataset) => {
    return await put(`dialogDefinitions/${dialogDefinitionId}/datasets/${id}`, dataset);
}

export const validateDialogDefinitionDataset = async (dialogDefinitionId,id,dataset) => {
    return await post(`dialogDefinitions/${dialogDefinitionId}/datasets/${id}/validate`, dataset);
}

export const deleteDialogDefinitionDataset = async (dialogDefinitionId, datasetId) => {
    return await destroy(`dialogDefinitions/${dialogDefinitionId}/datasets/${datasetId}`);
}