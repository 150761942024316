import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { getDefinitionLanguages, setTermsForLanguage, translateDefinitionLanguage } from "../../../api/definitionLanguage";
import { useToastAction } from "../../../hooks/useToastAction";
import { getDialogDefinition, getDialogDefinitionContainersWithElements, getDialogDefinitionElements, patchDialogDefinition } from "../../../api/dialogDefinition";
import Enumerable from "linq";
import { elementTypes, processTypes } from "../../../utils/constants";
import _ from 'lodash';
import { useDebounceCallback } from "@react-hook/debounce";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { getElementKey, getLanguageTermKey } from "../../../utils/features";
import Languages from "./languages";
import List from './list';
import { filterByArrayName, findActualTerm } from "../../../utils/definitionLanguages";
import { setElementHasAutomaticTranslationOff } from "../../../api/element";
import { setContainerHasAutomaticTranslationOff } from "../../../api/container";
import { useAutomaticTranslationProgress } from "./useAutomaticTranslationProgress";
import { setDigitalSigningHasAutomaticTranslationOff } from "../../../api/digitalSigning";
import ElementPropertiesForm from "./elementPropertiesForm";
import TranslationProgressBar from "./translationProgressBar";
import { setProcessHasAutomaticTranslationOff } from "../../../api/definitionProcesses";

export default function DialogLanguage() {
  const { dialogKey } = useParams();
  const loadDefinitionLanguagesAction = useToastAction();
  const setTermsAction = useToastAction(null, "save-language")
  const automaticTranslationCheckboxAction = useToastAction()
  const [definitionLanguages, setDefinitionLanguages] = useState();
  const [elements, setElements] = useState([]);
  const [organizedContainersAndElements, setOrganizedContainersAndElements] = useState([]);
  const [containersWithElements, setContainersWithElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState();
  const [dialogDefinition, setDialogDefinition] = useState([]);
  const automaticTranslationProgress = useAutomaticTranslationProgress(dialogKey, () => load())
  const elementTranslationRef = useRef();

  const saveTermsForLanguage = useDebounceCallback((currentLanguage, elementIdentifier, value, propertyKey, arrayName) => {
    setTermsAction.execute(async () => {
      let translateForDefinitionLanguageToExecute = []

      for (const definitionLanguage of definitionLanguages) {
        translateForDefinitionLanguageToExecute.push(translateForDefinitionLanguage(currentLanguage, definitionLanguage, elementIdentifier, value, propertyKey, arrayName))
      }

      await Promise.allSettled(translateForDefinitionLanguageToExecute)
    }, "Failed to save terms", "Saved all terms")
  }, 2000);

  const translateForDefinitionLanguage = async (currentLanguage, definitionLanguage, elementIdentifier, value, propertyKey, arrayName) => {
    if (definitionLanguage !== currentLanguage) {
      //If default language term has been edited, all other languages on the same item gonna be translated
      if (currentLanguage.default && !selectedElement?.hasAutomaticTranslationOff) {
        const term = findActualTerm(definitionLanguage, arrayName, elementIdentifier)
        const translation = value.length > 0 ? await translateDefinitionLanguage(value, currentLanguage.code, definitionLanguage.code) : ''
        _.set(term, propertyKey, translation)
      }
    }

    await setTermsForLanguage(definitionLanguage.id, dialogKey, definitionLanguage.languageTerms, definitionLanguage.containerLanguageTerms, definitionLanguage.digitalSigningTerm, definitionLanguage.processTerm)
  }

  const handleDefinitionLanguagesCreated = async (newDefinitionLanguage) => {
    const newDefinitionLanguages = [...definitionLanguages, newDefinitionLanguage]

    if (newDefinitionLanguages?.length > 1 && dialogDefinition.hasAutomaticTranslation) {
      automaticTranslationProgress.startChecking()
    }

    initializeDefinitionLanguages(newDefinitionLanguages, containersWithElements, dialogDefinition)
  }

  useEffect(() => {
    load();
  }, [])

  const load = async () => {
    await loadDefinitionLanguagesAction.execute(async () => {
      let [definitionLanguages, elements, containersWithElements, dialogDef] = await Promise.all([
        getDefinitionLanguages(dialogKey),
        getDialogDefinitionElements(dialogKey),
        getDialogDefinitionContainersWithElements(dialogKey),
        getDialogDefinition(dialogKey)
      ])
      initializeDefinitionLanguages(definitionLanguages, containersWithElements, dialogDef)

      setElements(elements)
      setDialogDefinition(dialogDef)
      setContainersWithElements(containersWithElements)
    }, "Failed to load languages")
  }

  const initializeDefinitionLanguages = (languages, containers, dialogDef) => {
    for (const definitionLanguage of languages) {
      initializeDefinitionLanguage(definitionLanguage, containers, dialogDef);
    }
    languages = Enumerable.from(languages).orderByDescending(l => l.default).toArray();
    setDefinitionLanguages(languages);
  }

  useEffect(() => {
    if (containersWithElements && dialogDefinition) {
      setOrganizedContainersAndElements(reorganizeObject(containersWithElements, dialogDefinition))
    }
  }, [containersWithElements, dialogDefinition])

  const reorganizeObject = (obj, dialogDef) => {
    const copyObj = _.cloneDeep(obj)
    const result = {};

    copyObj.forEach((section) => {
      section?.elements.forEach((element) => {
        const elementName = element.property
        insertFirstLetterOnArray(elementName, result, { ...element, isElement: true })
        
        if(element.connectedElements){
          element.connectedElements.forEach((conn) => {
            insertFirstLetterOnArray(conn.property, result, { ...conn, isElement: true })
          })
        }
      });

      const sectionName = section.name;
      insertFirstLetterOnArray(sectionName, result, { ...section, isContainer: true })
    });

    const digitalSigning = dialogDef?.showSigningOnFormComplete || dialogDef?.hasDigitalSigning;

    if (digitalSigning) {
      const digitalSigningItem = {
        name: 'Digital Signing',
        message: dialogDefinition?.digitalSigningMessage,
        isDigitalSigning: true
      };
      insertFirstLetterOnArray('Digital Signing', result, digitalSigningItem)
    }

    const process = dialogDef?.process === processTypes.multiStep;

    if(process) {
      const processItem = {
        name: "Process",
        isProcess: true
      }

      insertFirstLetterOnArray('Process', result, processItem)
    }

    const sortedResult = Object.fromEntries(
      Object.entries(result).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    );

    return sortedResult;
  }

  const insertFirstLetterOnArray = (name, array, item) => {
    const letter = name?.charAt(0).toUpperCase();

    if (!array[letter]) {
      array[letter] = [];
    }
    array[letter].push(item);
  }

  const initializeDefinitionLanguage = (definitionLanguage, containers, dialogDef) => {

    for (const container of containers) {
      const matchingContainerTerms = definitionLanguage.containerLanguageTerms.find(l => l.containerId === container.id)
      if (!matchingContainerTerms) {
        definitionLanguage.containerLanguageTerms.push({
          containerId: container.id,
          name: container.name
        })
      }

      for (const element of container.elements) {
        const matchingTerm = definitionLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))
        if (!matchingTerm) {
          definitionLanguage.languageTerms.push({
            elementId: element.id,
            elementProperty: element.property,
          })
        }
        let languageTerm = definitionLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))

        element.optionWithValues?.forEach(ov => {
          const matchingOption = languageTerm.options?.find(o => o.optionId === ov.id)
          if (!matchingOption) {
            if (!languageTerm.options) {
              languageTerm.options = []
            }
            languageTerm.options.push({
              optionId: ov.id
            })
          }
        })
        if(element.connectedElements){
          for (const connectedElement of element.connectedElements) {
            const matchingTerm = definitionLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(connectedElement))
            if (!matchingTerm) {
              definitionLanguage.languageTerms.push({
                elementId: connectedElement.id,
                elementProperty: connectedElement.property
              })
            }
          }
        }          
      }
    }
  }

  const onTermChange = (propertyKey, value, elementIdentifier, languageTerm, languageId, arrayName = 'languageTerms') => {
    const otherLanguages = definitionLanguages.filter(d => d.id !== languageId)
    let currentLanguage = definitionLanguages.find(d => d.id === languageId)
    _.set(languageTerm, propertyKey, value)

    currentLanguage = filterByArrayName(currentLanguage, elementIdentifier, languageTerm, arrayName)

    setDefinitionLanguages([
      ...otherLanguages,
      currentLanguage
    ])

    saveTermsForLanguage(currentLanguage, elementIdentifier, value, propertyKey, arrayName);
  }

  const containerTermsForLanguage = (language) => {
    return definitionLanguages.find(d => d.language === language)?.containerLanguageTerms || []
  }

  const digitalSigningTermForLanguage = (language) => {
    return definitionLanguages.find(d => d.language === language)?.digitalSigningTerm || {}
  }

  const processTermForLanguage = (language) => {
    return definitionLanguages.find(d => d.language === language)?.processTerm || {}
  }

  const hasMissingContainerTerms = (container) => {
    let hasMissingTerms = false;

    definitionLanguages.forEach(language => {
      const terms = containerTermsForLanguage(language.language)
      const matchingTerm = terms.find(t => t.containerId === container.id)
      hasMissingTerms = hasMissingTerms || !hasContainerTranslation(matchingTerm)
    });

    return hasMissingTerms;
  }

  const hasMissingDigitalSigningTerms = () => {
    let hasMissingTerms = false;

    definitionLanguages.forEach(language => {
      const terms = digitalSigningTermForLanguage(language.language)
      hasMissingTerms = hasMissingTerms || !hasDigitalSigningTranslation(terms)
    })

    return hasMissingTerms;
  }

  const hasMissingProcessTerms = () => {
    let hasMissingTerms = false;

    definitionLanguages.forEach(language => {
      const terms = processTermForLanguage(language.language)
      hasMissingTerms = hasMissingTerms || !hasProcessTranslation(terms)
    })

    return hasMissingTerms;
  }

  const hasContainerTranslation = (matchingTerm) => {
    return matchingTerm?.name
  }

  const hasDigitalSigningTranslation = (matchingTerm) => {
    return true
      && matchingTerm?.message
      && matchingTerm?.signNowMessage
      // && matchingTerm?.description
      // && matchingTerm?.error
      // && matchingTerm?.firstColumn
      // && matchingTerm?.secondColumn
      // && matchingTerm?.add
      && matchingTerm?.cancel
      // && matchingTerm?.submit
      && matchingTerm?.askOtherBtn
      && matchingTerm?.signNowBtn
  }

  const hasProcessTranslation = (matchingTerm) => {
    return true
      && matchingTerm?.header
      && matchingTerm?.description
      && matchingTerm?.step
      && matchingTerm?.current
      && matchingTerm?.signingDescription
      && matchingTerm?.signatory
  }

  if (!definitionLanguages || !elements) {
    return null;
  }

  const termsForLanguage = (language) => {
    return definitionLanguages.find(d => d.language === language)?.languageTerms || []
  }

  const hasMissingElementTerms = (element) => {
    let hasMissingTerms = false;

    definitionLanguages.forEach(langauge => {
      const terms = termsForLanguage(langauge.language)
      const matchingTerm = terms.find(t => getLanguageTermKey(t) === getElementKey(element))
      hasMissingTerms = hasMissingTerms || !hasTranslation(element, matchingTerm)
    });

    return hasMissingTerms;
  }

  const hasMissingTerms = (item) => {
    if (item.isContainer) {
      return hasMissingContainerTerms(item)
    } else if (item.isElement) {
      return hasMissingElementTerms(item)
    } else if (item.isDigitalSigning) {
      return hasMissingDigitalSigningTerms()
    } else if (item.isProcess) {
      return hasMissingProcessTerms()
    }
  }

  const hasTranslation = (selectedElement, matchingTerm) => {
    if (selectedElement.type === elementTypes.checkbox) {
      return matchingTerm.valueLabel && matchingTerm.description
    } else if (selectedElement.type === elementTypes.orderTable) {
      return matchingTerm.rows && matchingTerm.options
    } else if (selectedElement.type === elementTypes.matrix) {
      // Check if all options have matching translations
      for (const option of selectedElement.matrix?.options) {
        const matchingTranslatedOption = matchingTerm.matrixOptions?.find(o => o.id === option.id && o.label)
        if (!matchingTranslatedOption) {
          return false;
        }
      }
      // Check if all questions have matching translations
      for (const question of selectedElement.matrix?.questions) {
        const matchingTranslatedQuestion = matchingTerm.matrixQuestions?.find(q => q.id === question.id && q.label)
        if (!matchingTranslatedQuestion) {
          return false;
        }
      }
      return true;
    }
    else {
      return matchingTerm?.term
    }
  }

  const onAutomaticTranslationChanged = (event) => {
    const value = event.target.checked;
    const customMessage = `Automatic Translation is ${value ? 'enabled' : 'disabled'}.`
    automaticTranslationCheckboxAction.execute(async () => {
      const dialogDefinitionResult = await patchDialogDefinition(dialogKey, { hasAutomaticTranslation: value });
      setDialogDefinition(dialogDefinitionResult)
    }, 'Failed', customMessage)
  }

  const onAutomaticTranslationOff = async (event) => {
    const value = event.target.checked;
    const customMessage = `Automatic Translation is ${value ? 'Off' : 'On'} for this item.`

    let isContainer = false
    const containersWithElementsClone = _.cloneDeep(containersWithElements)
    for (const container of containersWithElementsClone) {
      if (container.id === selectedElement.id) {
        container.hasAutomaticTranslationOff = value;
        isContainer = true
        break;
      }

      for (const element of container.elements) {
        if (element.id === selectedElement.id) {
          element.hasAutomaticTranslationOff = value
          break;
        }
      }
    }

    if (selectedElement.isDigitalSigning) {
      automaticTranslationCheckboxAction.execute(async () => {
        await setDigitalSigningHasAutomaticTranslationOff(dialogDefinition.id, value);
        setSelectedElement({ ...selectedElement, hasAutomaticTranslationOff: value })
        setDialogDefinition({...dialogDefinition, digitalSigningHasAutomaticTranslationOff: value})

      }, 'Failed', customMessage)
    } else if (selectedElement.isProcess){
      automaticTranslationCheckboxAction.execute(async () => {
        await setProcessHasAutomaticTranslationOff(dialogDefinition.id, value)
        setSelectedElement({ ...selectedElement, hasAutomaticTranslationOff: value })
        setDialogDefinition({...dialogDefinition, processHasAutomaticTranslationOff: value})
      }, 'Failed', customMessage)
    }
    else if (isContainer) {
      automaticTranslationCheckboxAction.execute(async () => {
        await setContainerHasAutomaticTranslationOff(dialogDefinition.id, selectedElement.id, value)
        setSelectedElement({ ...selectedElement, hasAutomaticTranslationOff: value })
        setContainersWithElements(containersWithElementsClone)
      }, 'Failed', customMessage)
    }
    else {
      automaticTranslationCheckboxAction.execute(async () => {
        await setElementHasAutomaticTranslationOff(dialogDefinition.id, selectedElement.id, value)
        setSelectedElement({ ...selectedElement, hasAutomaticTranslationOff: value })
        setContainersWithElements(containersWithElementsClone)
      }, 'Failed', customMessage)
    }
  }

  const onSetSelectElement = (item) => {
    elementTranslationRef?.current.scrollIntoView({ behavior: 'smooth' });
    setSelectedElement(item)
  }

  return (
    <>
      {
        definitionLanguages &&
        <div className="h-full">
          <div className="pb-4">
            <div className="pb-5 border-b border-gray-200 mt-10 mb-6">
              <h1 className="text-2xl leading-6 font-medium  text-gray-900">Smartform Translation</h1>
              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                Please select the field you want to translate.
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <Languages
                onDefinitionLanguagesCreated={handleDefinitionLanguagesCreated}
                dialogKey={dialogKey}
                dialogDefinition={dialogDefinition}
                definitionLanguages={definitionLanguages}
                setDefinitionLanguages={setDefinitionLanguages}
                onAutomaticTranslationChanged={onAutomaticTranslationChanged}
                automaticTranslationInProgress={automaticTranslationProgress.inProgress}
              />
            </div>
            {
              definitionLanguages.length < 2 &&
              <div className="flex flex-1 align-center justify-center items-center">
                <label className="text-gray-500 text-center w-4/5 border border-gray-300 p-20">You need to define two languages before you can make a translation of elements in your Smartform.</label>
              </div>
            }
          </div>
          <TranslationProgressBar automaticTranslationProgress={automaticTranslationProgress} />
          {
            definitionLanguages.length >= 2 && !automaticTranslationProgress.inProgress && !automaticTranslationProgress.isChecking &&
            (
              <div className="mt-10">
                <div className="">
                  <div className="pb-5 border-b border-gray-200 mt-10 mb-6">
                    <h2 className="text-2xl leading-6 font-medium text-gray-900">Translated items</h2>
                  </div>
                </div>
                <div className="flex">
                  <List
                    data={organizedContainersAndElements}
                    setSelectedElement={onSetSelectElement}
                    selectedElementId={selectedElement?.id}
                    hasMissingTerms={hasMissingTerms}
                  />
                  <div ref={elementTranslationRef} className="ml-6 bg-white overflow-hidden shadow rounded-lg items-center h-full w-full">
                    {
                      !selectedElement ?
                        (<h4 className="text-lg px-4 py-5 sm:px-6 leading-6 font-xs text-gray-900">Please select a field</h4>)
                        :
                        (<h4 className="text-lg px-4 pt-5 pb-3 sm:px-6 leading-6 text-gray-900">Translation for
                          <span className="inline-flex items-center ml-1 px-3 py-0.5 rounded-full text-medium font-medium bg-gray-100 text-gray-800">{selectedElement.property || selectedElement.name}</span>
                        </h4>)
                    }
                    {
                      selectedElement &&
                      <div className="flex sm:px-6 items-center border-b border-gray-200 pb-4 pr-2">
                        <input type="checkbox" name='hasAutomaticTranslationOff' 
                            checked={selectedElement?.isDigitalSigning
                                  ? dialogDefinition.digitalSigningHasAutomaticTranslationOff
                                  : selectedElement?.isProcess
                                  ? dialogDefinition.processHasAutomaticTranslationOff
                                  : selectedElement?.hasAutomaticTranslationOff}
                            onChange={onAutomaticTranslationOff} 
                        />
                        <label className="ml-2 text-sm leading-6 font-medium text-gray-900">Turn off automatic translation for this item</label>
                      </div>
                    }
                    <div className="px-4 sm:px-6 divide-y divide-gray-200">
                      {
                        selectedElement && definitionLanguages && definitionLanguages.map(language => {
                          let currentTerms;
                          let matchingTerm;
                          let hasTranslationVerified;
                          if (selectedElement.isContainer) {
                            currentTerms = containerTermsForLanguage(language.language);
                            matchingTerm = currentTerms.find(l => l.containerId === selectedElement.id)
                            hasTranslationVerified = hasContainerTranslation(matchingTerm);
                          } else if (selectedElement.isDigitalSigning) {
                            currentTerms = digitalSigningTermForLanguage(language.language)
                            matchingTerm = currentTerms
                            hasTranslationVerified = hasDigitalSigningTranslation(matchingTerm)
                          } else if (selectedElement.isProcess) {
                            currentTerms = processTermForLanguage(language.language)
                            matchingTerm = currentTerms
                            hasTranslationVerified = hasProcessTranslation(matchingTerm)
                          } else {
                            currentTerms = termsForLanguage(language.language);
                            matchingTerm = currentTerms.find(l => getLanguageTermKey(l) === getElementKey(selectedElement))
                            hasTranslationVerified = hasTranslation(selectedElement, matchingTerm);
                          }

                          return matchingTerm && (
                            <div className="p-4 py-8"
                              key={`language-${language.id}-element-${selectedElement.id}`}
                            >
                              <div className="flex justify-between">
                                <div className="flex mb-4 p-4 justify-center items-center h-12 border border-gray-200 bg-gray-50 rounded-md text-center relative">
                                  {
                                    hasTranslationVerified ?
                                      <CheckCircleIcon className="absolute -left-3 -top-3 h-6 w-6 text-green-600" aria-hidden="true" />
                                      : <XCircleIcon className="absolute -left-3 -top-3 h-6 w-6 text-red-600" aria-hidden="true" />
                                  }
                                  <div className="text-sm font-medium text-gray-700">
                                    {language.language}
                                  </div>
                                </div>
                              </div>
                              <ElementPropertiesForm
                                selectedElement={selectedElement}
                                matchingTerm={matchingTerm}
                                onTermChange={onTermChange}
                                language={language}
                                dialogDefinition={dialogDefinition}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      }
    </>
  );
}
