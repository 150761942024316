import { ibanCountriesOptions } from "../../utils/ibanCountries";
import Select from "react-select";

export default function SelectCountry({ inputId, setCountrySelect, inputRef }) {
    const flagsJsx = (country) => {
        return (
            <div className="flex items-center h-8">
                <img
                    className="w-10 mr-2"
                    src={country.image}
                    alt={country.label}
                />
                <span>{country.label}</span>
            </div>
        );
    };

    return (
        <div className="w-2/4 h-full">
            <Select
                id={`country-flag-select-${inputId}`}
                width="500"
                options={ibanCountriesOptions}
                formatOptionLabel={(country) => flagsJsx(country)}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                })}
                onChange={(country) => {
                    setCountrySelect(country.value);
                    inputRef.current.focus();
                }}
            />
        </div>
    );
}
