import { useContext, useEffect, useState } from "react";
import { FormContext, DialogModes, RowContext } from "../../contexts";
import { useElementDesign } from "../useElementDesign";
import { InlineActionbarElementWrapper } from "../../components/InlineActionbarElementWrapper";
import { useElementStyle } from "../useElementStyle";
import { useDefinitionManager } from "../../screens/Admin/DialogDesign/useDefinitionManager";
import { useSelector } from "react-redux";
import { useMultiStep } from "../../hooks/useMultiStep";
import InlineEditing from "../../components/InlineEditing";
import { getDialogValueKey, getElementKey } from "../../utils/features";
import { ElementFromType } from "../../structures/Column";

export default function RadioButtonGroup({ data }) {
  const { textStyle, textClassName } = useElementStyle(data);
  const definitionManager = useDefinitionManager();
  const currentDialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const { isPreviewTemplateMode, isEditMode, isPdfMode } = useContext(DialogModes)
  const classPrefix = "radio-button-group";
  const elementDesign = useElementDesign(data.id, data);
  const multiStep = useMultiStep(data)
  const [value, setValue] = useState(data.value);

  let requiredRadioGroup;
  let groupName;
  let name;
  if (!isPreviewTemplateMode) {
    const row = definitionManager.findRowFromElementId(currentDialogDefinition, data.id)
    requiredRadioGroup = row?.requiredRadioGroup;
    groupName = row?.radioGroupName ? row.radioGroupName : row?.id;
    name = row?.radioGroupName ? row.radioGroupName : `${classPrefix}-${groupName || data.id}`;
    name = name.replace(" ", "");
  } else {
    name = `${classPrefix}-${''}`;
  }
  
  const labelStyle = textStyle(data?.text);
  const labelClass = textClassName(data?.text);
  const formContext = useContext(FormContext);

  let currentValueTemp;
  let groupValue;
  let onChange;
  let radioButtons = document.querySelectorAll(`input[name=${name}]`);

  if (formContext) { 
    groupName = formContext?.inputValues[getDialogValueKey(data)]?.group || groupName;
    groupValue = formContext?.inputValues[groupName]?.value;
    currentValueTemp = formContext?.inputValues[getDialogValueKey(data)]?.checkedValue;
  } else {
    for (const radioButton of radioButtons) {
      if (radioButton.checked) {
        currentValueTemp = radioButton.value;
        break;
      }
    }
  }  

  const existError = () => formContext?.errorsWithDetail?.find(err => err.id === groupName);
  
  onChange = (e) => {
    currentValueTemp = value;
    if (formContext) {
      const errorObj = existError();

      //remove error if exist.
      if(errorObj) {
        formContext.addOrRemoveErrorInput(errorObj, "remove")
      } 
      formContext.updateValue(getDialogValueKey(data), e.target.value, groupName);
    } else {
      setValue(!value);
      setCurrentValue(!currentValueTemp)
    }
    if(groupName)
      formContext?.reusablePropertyChanged(groupName, currentValueTemp)
  };

  const [currentValue, setCurrentValue] = useState(currentValueTemp);

  const isReadOnlyDependency = elementDesign.isReadOnlyDependency(data?.dependencies?.value, data?.dependencies?.type);
  const id = getElementKey(data);
    
  useEffect(() => {
    if(groupName && groupValue)
      formContext?.reusablePropertyChanged(groupName, groupValue)
  }, [])
 
  return (
    <div className='lg:flex items-end w-full'>
      <InlineActionbarElementWrapper additionalClasses="w-full" designElement={elementDesign} openElementProperties>
        <div className={`flex items-center pl-1`}>
          {
            isPdfMode ?
            <label className=""> 
              {
              currentValue ? 
              <span style={{fontFamily: "Verdana"}} className="text-2xl" dangerouslySetInnerHTML={{ __html: '&#x2611;' }} /> 
              : 
              <span style={{fontFamily: "Verdana"}} className="text-2xl" dangerouslySetInnerHTML={{ __html: '&#x2610;' }} />
              }
            </label>
            :
            <>
            <input
              id={id}
              type="radio"
              value={data?.value}
              name={name}
              className={`h-4 w-4 cursor-pointer border-gray-300 mr-2 ${existError() ? "border-red-600 border-2" : ""}`}
              checked={data?.value === groupValue}
              onClick={onChange}
              disabled={elementDesign.isReadOnly() || multiStep.isReadOnly || isReadOnlyDependency}
              />
              {elementDesign.requiredFieldIndicator(requiredRadioGroup)}
            </>
          }
          <InlineEditing
            initData={data}
            style={labelStyle}
            classes={`element flex-1 ${labelClass}`}
            name='label'
            id={`label-${data.id}`}
          >
            <label
              htmlFor={id}
              className={`element flex-1 ${labelClass}`}
              style={labelStyle}
            >
              {elementDesign.translateTerm(data?.label)}
              {elementDesign.translateHelpText(data)}
            </label>
          </InlineEditing>
        </div>
      </InlineActionbarElementWrapper>

      {
        (groupValue === data?.value || isEditMode) && data?.connectedElements?.map((element, index) => {
          return (
            <div key={index} className="ml-3">  
              <ElementFromType isParentReadOnly={multiStep.isReadOnly} element={element} isConnectedElement /> 
            </div>
          )
        })
      }

        {elementDesign.errorMessage()}

    </div>
  );
}
