import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useContext, useEffect, useState } from 'react';
import { getSmartObject } from '../../api/public/smartObject';
import { DialogModes, FormContext } from '../../contexts';
import SmartObjectModal from './smartObjectModal';
import { v4 as uuidv4 } from 'uuid';
import { getDialogValueKey } from '../../utils/features';
import { PencilAltIcon, TrashIcon, CheckIcon, XIcon } from '@heroicons/react/solid';

export default function SmartTable({ data, ...props }) {
    const formContext = useContext(FormContext);
    const elementDesign = useElementDesign(data.id, data);
    const { isEditMode, isPdfMode } = useContext(DialogModes);
    const [smartObject, setSmartObject] = useState({});
    const [rows, setRows] = useState([]);
    const [showNewModal, setShowNewModal] = useState(false);
    const [columns, setColumns] = useState([]);
    const [currentRow, setCurrentRow] = useState({});
    const isSmartObjectNotConnected = smartObject?.id === undefined;
    const buttonText = data?.smartTable?.addButtonText
        ? elementDesign.translateTerm(data.smartTable.addButtonText, "displayLabel")
        : smartObject?.id === undefined
            ? 'Connect a SmartObject'
            : `Add item`

    useEffect(() => {
        loadSmartObject();
        formContext?.reusablePropertyChanged(data.property, formContext?.inputValues[getDialogValueKey(data)]?.smartTableValues)
    }, [data])

    const createDummyData = (smartObject) => {
        if (isEditMode) {
            // create dummy data. Create 5 items in an array that match the smart object fields
            const rows = [];
            for (let i = 0; i < data?.smartTable?.maxRows; i++) {
                if (rows.length >= 50) break;
                const dummyItem = {};
                smartObject.smartObjectProperties.forEach(field => {
                    dummyItem[field.name] = field.name + ' ' + i;
                })
                rows.push(dummyItem);
            }
            setRows(rows);
        }
    }

    const loadSmartObject = async () => {
        if (!data?.smartTable?.smartObjectId) return;

        const smartObject = await getSmartObject(data?.smartTable?.smartObjectId);
        setSmartObject(smartObject);

        if (!formContext) {
            createDummyData(smartObject);
        } else {
            let smartTableRows = [];

            try {
                smartTableRows = JSON.parse(formContext.inputValues[getDialogValueKey(data)]?.smartTableValues || '') || []
            }
            catch (e) { }

            setRows(smartTableRows);
        }

        if (data?.smartTableHeaders?.length > 0) {
            setColumns(data?.smartTableHeaders);
        }
        else {
            // go through each property and create a header
            // only add if there is new properties and remove if there are properties that no longer exist
            const newHeaders = [];
            smartObject?.smartObjectProperties?.forEach(property => {
                newHeaders.push({
                    id: property.id,
                    name: property.name,
                    display: property.label,
                    span: property.span
                })
            })

            setColumns(newHeaders);
        }
    }

    const buttonStyle = () => {

        return {
            backgroundColor: data?.smartTable?.addButtonBackground || '#000',
            color: data?.smartTable?.addButtonTextColor || '#fff',
        }
    }

    const handleAddNewRow = (e) => {
        e.preventDefault();
        setCurrentRow({});
        setShowNewModal(true);
    }

    const handleEditRow = (e, row) => {
        e.preventDefault();
        setCurrentRow(row);
        setShowNewModal(true);
    }

    const handleDeleteRow = (e, row) => {
        e.preventDefault();
        const index = rows.findIndex(r => r.id === row.id);
        rows.splice(index, 1);
        setRows([...rows]);
        formContext.updateSmartTable(getDialogValueKey(data), rows);
    }

    const handleSelectedChange = (row, isChecked) => {
        row.selected = isChecked;
        const index = rows.findIndex(r => r.id === row.id);
        rows[index] = row;

        formContext?.updateSmartTable(getDialogValueKey(data), rows);
    }

    const handleSave = (row) => {
        if (row.id) {
            const index = rows.findIndex(r => r.id === row.id);
            rows[index] = row;
        }
        else {
            row.id = uuidv4();
            rows.push(row);
        }

        formContext.updateSmartTable(getDialogValueKey(data), rows);
    }

    const tableStyle = {
        borderWidth: data?.borderWidth ? `${data.borderWidth}` : 'inherit',
        borderColor: data?.borderWidth ? `${data.borderColor}` : 'inherit',
    }

    const headerStyle = {
        color: data?.smartTable?.headerTextColor || '#000',
        fontSize: data?.smartTable?.headerTextSize ? data?.smartTable?.headerTextSize + 'px' : "inherit"
    }

    const cellStyle = {
        color: data?.smartTable?.cellTextColor || '#000',
        fontSize: data?.smartTable?.cellTextSize ? data?.smartTable?.cellTextSize + 'px' : "inherit"
    }

    const maxRowsVerify = rows.length >= data.smartTable?.maxRows;

    const valueWithVerificationType = (value) => {
        const isTypeBoolean = typeof value === "boolean";

        if (isTypeBoolean) {
            return value
                ? <CheckIcon className="h-5 w-5" aria-hidden="true" />
                : <XIcon className="h-5 w-5" aria-hidden="true" />
        } else {
            return value
        }
    }

    return (
        <>
            {
                isSmartObjectNotConnected && formContext?.isFormEnabled ?
                    <></>
                    :
                    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
                        <div className='flex flex-col gap-6'>
                            <div className='flex justify-between gap-2'>
                                <div className='flex flex-col gap-1'>
                                    <div className='text-md'>{elementDesign.translateTerm(data?.smartTable?.heading || smartObject?.name || 'Connect a smart object')}</div>
                                    <div className='text-sm font-medium text-gray-500'>{elementDesign.translateTerm(data?.smartTable?.description, "description")}</div>
                                </div>
                                {
                                    ((formContext?.isFormEnabled || isEditMode) && (!isPdfMode)) && !data?.smartTable?.preventEditItems &&
                                    <div className='flex-none flex-grow-0'>
                                        <button
                                            style={buttonStyle()}
                                            className='sf-smart-table-add-button w-auto inline-flex rounded-md shadow-sm px-4 py-2 text-base font-medium sm:mt-0 sm:text-sm'
                                            onClick={handleAddNewRow}
                                            disabled={isSmartObjectNotConnected || maxRowsVerify}
                                        >
                                            {buttonText}
                                        </button>
                                    </div>
                                }
                            </div>
                            {
                                data?.smartTable?.stripedRows &&
                                <style type="text/css">
                                    {
                                        data?.smartTable?.stripesEvenColor &&
                                        `tbody.table-${data?.id} tr:nth-child(even) { background-color: ${data?.smartTable?.stripesEvenColor} }`
                                    }
                                    {
                                        data?.smartTable?.stripesOddColor &&
                                        `tbody.table-${data?.id} tr:nth-child(odd) { background-color: ${data?.smartTable?.stripesOddColor} }`
                                    }
                                </style>
                            }

                            <table className={`sf-smart-table w-full divide-y divide-gray-300 table-fixed`} style={tableStyle}>
                                <thead className='sf-smart-table-header'>
                                    <tr className='sf-smart-table-header-row'>
                                        {
                                            data?.smartTable?.makeSelectable &&
                                            <th scope="col" className="sf-smart-table-header-column px-3 py-3.5 text-left text-sm font-semibold w-10" style={headerStyle}>
                                            </th>
                                        }
                                        {
                                            data?.smartTable?.showRowNumber &&
                                            <th scope="col" className="sf-smart-table-header-column px-3 py-3.5 text-left text-sm font-semibold" style={headerStyle}>
                                                #
                                            </th>
                                        }
                                        {
                                            columns?.filter(m => !m.hide)?.map((column, index) => (
                                                <th key={index} colSpan={column.span} scope="col" className="sf-smart-table-header-column truncate px-3 py-3.5 text-left text-sm font-semibold" style={headerStyle}>
                                                    {elementDesign.translateColumnsSmartTable(column.id, column.display)}
                                                </th>
                                            ))
                                        }
                                        <th scope="col" className="w-16 relative py-3.5 pl-3 pr-4 sm:pr-0">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={`sf-smart-table-body table-${data?.id} divide-y divide-gray-200`}>
                                    {
                                        rows?.map((row, index) => (
                                            <tr key={index} className={`sf-smart-table-body-row ${data?.smartTable.stripedRows ? 'even:bg-gray-50' : ''}`}>
                                                {
                                                    data?.smartTable?.makeSelectable &&
                                                    <td className="px-3 py-3.5 whitespace-nowrap text-sm" style={cellStyle}>
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => handleSelectedChange(row, e.target.checked)}
                                                            // name={name}
                                                            // id={id}
                                                            disabled={!formContext?.isFormEnabled}
                                                            checked={row?.selected}
                                                            // onChange={(evt) => onChange(evt.target.checked, name)}
                                                            className={`sf-smart-table-body-selected-checkbox mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded`}
                                                        />
                                                    </td>
                                                }
                                                {
                                                    data?.smartTable?.showRowNumber &&
                                                    <td className="sf-smart-table-body-row-number px-3 py-3.5 whitespace-nowrap text-sm" style={cellStyle}>
                                                        {index + 1}
                                                    </td>
                                                }
                                                {
                                                    columns?.filter(m => !m.hide)?.map((column, index) => (
                                                        <td key={index} colSpan={column.span} className="sf-smart-table-body-column truncate px-3 py-3.5 text-sm" style={cellStyle}>
                                                            {valueWithVerificationType(row[column.name])}
                                                        </td>
                                                    ))
                                                }
                                                <td className='w-16'>
                                                    {
                                                        ((formContext?.isFormEnabled || isEditMode) && (!isPdfMode)) && !data?.smartTable?.preventEditItems && 
                                                        <div style={cellStyle} className='flex justify-end gap-2 mr-1'>
                                                            <button className='sf-smart-table-body-action h-5 w-5 text-right' onClick={(e) => handleEditRow(e, row)}>
                                                                <PencilAltIcon className="sf-smart-table-body-action-icon h-5 w-5 text-right" aria-hidden="true" />
                                                            </button>
                                                            <button className='sf-smart-table-body-action h-5 w-5 text-right' onClick={(e) => handleDeleteRow(e, row)}>
                                                                <TrashIcon className="sf-smart-table-body-action-icon h-5 w-5 text-right" aria-hidden="true" />
                                                            </button>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        {
                            !isEditMode && !isSmartObjectNotConnected &&
                            <SmartObjectModal buttonStyle={buttonStyle()} show={showNewModal} setShow={setShowNewModal} rowData={currentRow} save={handleSave} smartObject={smartObject} data={data} columns={columns} />
                        }

                    </InlineActionbarElementWrapper>
            }
        </>
    )
}