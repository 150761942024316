import React from 'react';

export const FormContext = React.createContext();
export const ShowHideContext = React.createContext();
export const ElementContext = React.createContext();
export const ElementPropertiesContext = React.createContext();
export const RowContext = React.createContext();
export const ColumnContext = React.createContext();
export const ContainerContext = React.createContext();
export const DesignActionbarContext = React.createContext();
export const OidcRoutesContext = React.createContext();
export const ContainerLockedContext = React.createContext();
export const QuickDesignContext = React.createContext();
export const DialogNotificationContext = React.createContext();
export const TopbarContext = React.createContext();
export const DialogModes = React.createContext();
export const GlobalDndContext = React.createContext();
export const HelperSidebarContext = React.createContext();
export const FolderContext = React.createContext();
export const AdminlayoutContext = React.createContext();
export const DatasetContext = React.createContext();